/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  coupons: [],
  isLoading: false,
  referralCode: null,
  usedReferralCodes: [],
  recommendations: [],
  isSendingEmailLoading: false,
  isGeneratingUserCodeLoading: false,
};

export const getUserReferralCodeCoupons = createAsyncThunk('user/getUserReferralCodeCoupons', async () => {
  return API.CouponsService.getUserReferralCodeCoupons();
});

export const createUserReferralCodeCoupons = createAsyncThunk('user/createUserReferralCodeCoupons', async () => {
  return API.CouponsService.createUserReferralCodeCoupons();
});

export const getUserUsedReferralCodesCoupons = createAsyncThunk(
  'user/getUserUsedReferralCodesCoupons',
  async (pageNo, limit) => {
    return API.CouponsService.getUserUsedReferralCodesCoupons(pageNo, limit);
  },
);

export const sendReferralCodeCoupons = createAsyncThunk('user/sendReferralCodeCoupons', async payload => {
  return API.CouponsService.sendReferralCodeCoupons(payload);
});

export const getCoupons = createAsyncThunk('coupons/getCoupons', async () => {
  return API.CouponsService.getCoupons();
});

export const archiveCoupon = createAsyncThunk('coupons/archive', async payload => {
  return API.CouponsService.archiveCoupon(payload);
});

export const addCoupon = createAsyncThunk('coupons/addCoupon', async payload => {
  return API.CouponsService.addCoupon(payload);
});

export const changeStatusOfRecommendationCode = createAsyncThunk(
  'user/changeStatusOfRecommendationCode',
  async codeId => {
    return API.CouponsService.changeStatusOfRecommendationCode(codeId);
  },
);

export const getAllRecommendations = createAsyncThunk(
  'user/getAllRecommendations',
  async ({ pageNo, limit, search }) => {
    return API.CouponsService.getAllRecommendations({ pageNo, limit, search });
  },
);

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    addSubmessageToPost: (state, action) => {
      const { messages } = state;
      const newMessages = messages.map(message => {
        if (message._id === action.payload.messageId) {
          message.submessages.push(action.payload.message);
        }
        return message;
      });
      state.messages = newMessages;
    },
  },
  extraReducers: {
    [getCoupons.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCoupons.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać kuponów!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getCoupons.fulfilled.toString()]: (state, action) => {
      state.coupons = action.payload.data;
      state.isLoading = false;
    },
    [archiveCoupon.pending.toString()]: state => {
      state.isLoading = true;
    },
    [archiveCoupon.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się zarchiwizować kuponu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [archiveCoupon.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Zarchiwizowano kupon!',
        placement: 'bottomRight',
      });
    },
    [addCoupon.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addCoupon.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać kuponu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addCoupon.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano kupon!',
        placement: 'bottomRight',
      });
    },
    [sendReferralCodeCoupons.rejected.toString()]: (state, action) => {
      state.isSendingEmailLoading = false;
      state.sendEmailError = action.error.message;
      notification.error({
        message: 'Nie udało wysłać wiadomości!',
        description:
          action.error.message === 'Email already sent'
            ? 'Kod został już wysłany na podany adres email'
            : action.error.message === 'Too many emails sent today'
            ? 'Możesz wysłać maksymalnie 10 emaili dziennie'
            : action.error.message === 'Email is required'
            ? 'Email jest wymagany'
            : action.error.message === 'User does not have a recommendation code'
            ? 'Uczeń nie posiada kodu polecającego'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [sendReferralCodeCoupons.fulfilled.toString()]: state => {
      state.isSendingEmailLoading = false;
      notification.success({
        message: 'Wiadomość została wysłana',
        placement: 'bottomRight',
      });
    },
    [createUserReferralCodeCoupons.pending.toString()]: state => {
      state.isGeneratingUserCodeLoading = true;
    },
    [createUserReferralCodeCoupons.rejected.toString()]: (state, action) => {
      state.isGeneratingUserCodeLoading = false;
      notification.error({
        message: 'Nie udało wygenerować kodu!',
        description:
          action.error.message === 'User already has recommendation code'
            ? 'Kod został już raz wygenerowany!'
            : action.error.message === 'User has not bought any packages'
            ? 'Użytkownik nie kupił jeszcze żadnego pakietu'
            : action.error.message === 'User has not bought any subscription'
            ? 'Użytkownik nie kupił jeszcze żadnego pakietu'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [createUserReferralCodeCoupons.fulfilled.toString()]: state => {
      state.isGeneratingUserCodeLoading = false;
      state.sendEmailSuccess = true;
      notification.success({
        message: 'Kod został wygenerowany pomyślnie',
        placement: 'bottomRight',
      });
    },
    [getUserReferralCodeCoupons.fulfilled.toString()]: (state, action) => {
      state.referralCode = action.payload.data;
    },
    [getUserUsedReferralCodesCoupons.fulfilled.toString()]: (state, action) => {
      state.usedReferralCodes = action.payload.data;
    },
    [getAllRecommendations.fulfilled.toString()]: (state, action) => {
      state.recommendations = action.payload.data;
    },
  },
});

export const { addSubmessageToPost } = couponsSlice.actions;

export default couponsSlice.reducer;
