import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Coupons = lazy(() => import('../container/coupons'));
const Recommendations = lazy(() => import('../container/coupons/overview/Recommendations'));

const CouponsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Coupons} />
      <Route exact path={`${path}/recommendations`} component={Recommendations} />
    </Switch>
  );
};

export default CouponsRoutes;
