/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const addWarning = payload => APIService.post('warnings', { ...payload });

export const getWarning = (userId, subjectId) => APIService.get(`warnings/users/${userId}/subjects/${subjectId}`);

export const getAllWarnings = (page, limit, search) =>
  APIService.get(`warnings?page=${page}&limit=${limit}&search=${search}`);

export const updateWarning = (warningId, payload) => APIService.put(`warnings/${warningId}`, { ...payload });
