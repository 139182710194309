import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MessageBox = ({ isActive }) => {
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };
  return (
    <div className="message">
      <Link to={isActive ? '/shop/cart' : ''} className="head-example" id={isActive ? '' : 'not-allowed'}>
        <FeatherIcon
          icon={isActive ? 'shopping-cart' : 'lock'}
          size={20}
          className="shopping-cart-icon"
          style={{ color: '#5D5F79' }}
        />
      </Link>
    </div>
  );
};

export default MessageBox;
