import actions from './actions';

const { setCollapsed } = actions;

const toggleSider = collapsed => {
  return dispatch => {
    dispatch(setCollapsed(collapsed));
  };
};

export { toggleSider };
