/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  carts: [],
  cart: [],
  couponError: false,
  couponSuccess: false,
  recommendationError: false,
  recommendationSuccess: false,
  removingRecommendationSuccess: false,
  removingCouponSuccess: false,
};

export const getStudentCarts = createAsyncThunk('cart/getStudentCarts', async () => {
  return API.CartService.getStudentCarts();
});

export const getCart = createAsyncThunk('cart/getCart', async () => {
  return API.CartService.getCart();
});

export const addToCart = createAsyncThunk('cart/addToCart', async payload => {
  return API.CartService.addToCart(payload);
});

export const removeProductFromCart = createAsyncThunk('cart/removeProductFromCart', async payload => {
  return API.CartService.removeProductFromCart(payload);
});

export const setCartCoupon = createAsyncThunk('cart/setCartCoupon', async payload => {
  return API.CartService.setCartCoupon(payload);
});

export const removeCartCoupon = createAsyncThunk('cart/removeCartCoupon', async () => {
  return API.CartService.setCartCoupon({ couponText: null });
});

export const setCartRecommendationCode = createAsyncThunk('cart/setCartRecommendationCode', async payload => {
  return API.CartService.serCartRecommendationCode(payload);
});

export const removeCartRecommendationCode = createAsyncThunk('cart/removeCartRecommendationCode', async () => {
  return API.CartService.serCartRecommendationCode({ recommendationCode: null });
});

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: {
    [getStudentCarts.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentCarts.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentCarts.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.carts = action.payload.data;
    },
    [getCart.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCart.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getCart.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.cart = action.payload.data;
    },
    [addToCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Ups, mały problem!',
        description: `${
          action.error?.message === 'Product doesnt exists'
            ? 'Produkt nie istnieje'
            : action.error?.message === 'You have to add previous product to the cart first'
            ? 'Dodaj produkty do koszyka po kolei, np. najpierw pakiet 1, potem pakiet 2 itd.'
            : action.error?.message === 'This product is already in the cart'
            ? 'Produkt jest już w koszyku!'
            : action.error?.message
        }`,
        placement: 'bottomRight',
      });
    },
    [addToCart.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano produkt do koszyka!`,
        description: 'Wybrany produkt został dodany do koszyka.',
        placement: 'bottomRight',
      });
    },
    [removeProductFromCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć produktu z koszyka!',
        description: `${
          action.error?.message === 'Product doesnt exists'
            ? 'Produkt nie istnieje'
            : action.error.message === 'You have to remove higher products from the cart first'
            ? 'Usuń produkty z koszyka w odpowiedniej kolejności, np. jeśli chcesz usunąć pakiet 3, to najpierw usuń pakiet 4 itd.'
            : action.error.message === 'Product is already removed'
            ? 'Produkt został już usunięty'
            : action.error?.message
        }`,
        placement: 'bottomRight',
      });
    },
    [removeProductFromCart.fulfilled.toString()]: () => {
      notification.success({
        message: `Usunięto produkt z koszyka!`,
        description: 'Wybrany produkt został usunięty z koszyka.',
        placement: 'bottomRight',
      });
    },
    [setCartCoupon.rejected.toString()]: (state, action) => {
      state.couponError = true;
      notification.error({
        message: 'Nie udało się dodać kodu rabatowego!',
        description: `${
          action.error?.message === 'Wrong coupon'
            ? 'Nieprawidłowy kod rabatowy'
            : action.error?.message === 'Coupon already used'
            ? 'Kupon został już użyty'
            : action.error?.message
        }`,
        placement: 'bottomRight',
      });
    },
    [setCartCoupon.fulfilled.toString()]: state => {
      state.removingCouponSuccess = false;
      state.couponError = false;
      state.couponSuccess = true;
      notification.success({
        message: `Dodano kod rabatowy!`,
        description: 'Kod rabatowy został dodany do koszyka.',
        placement: 'bottomRight',
      });
    },
    [setCartRecommendationCode.rejected.toString()]: (state, action) => {
      state.recommendationError = true;
      state.recommendationSuccess = false;
      state.removingRecommendationSuccess = false;
      notification.error({
        message: 'Nie udało się dodać kodu polecającego!',
        description: `Upewni się, że kod polecający jest poprawny.`,
        placement: 'bottomRight',
      });
    },
    [setCartRecommendationCode.fulfilled.toString()]: state => {
      state.recommendationError = false;
      state.recommendationSuccess = true;
      state.removingRecommendationSuccess = false;
      notification.success({
        message: `Dodano kod polecający!`,
        description: 'Kod polecający został dodany do koszyka.',
        placement: 'bottomRight',
      });
    },
    [removeCartRecommendationCode.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć kodu polecającego!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeCartRecommendationCode.fulfilled.toString()]: state => {
      state.recommendationSuccess = false;
      state.removingRecommendationSuccess = true;
      notification.success({
        message: `Usunięto kod polecający!`,
        description: 'Kod polecający został usunięty z koszyka.',
        placement: 'bottomRight',
      });
    },

    [removeCartCoupon.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć kodu rabatowego!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeCartCoupon.fulfilled.toString()]: state => {
      state.couponSuccess = false;
      state.removingCouponSuccess = true;
      notification.success({
        message: `Usunięto kod rabatowy!`,
        description: 'Kod rabatowy został usunięty z koszyka.',
        placement: 'bottomRight',
      });
    },
  },
});

export default cartSlice.reducer;
