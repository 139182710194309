import React from 'react';
import { Button, Layout } from 'antd';
import { useDispatch } from 'react-redux';
import actions from '../redux/announcementModal/actions';

const AnnouncementHeader = ({ text, buttonText }) => {
  const { Header } = Layout;
  const dispatch = useDispatch();

  const onClickGoToDetails = () => {
    dispatch(actions.setAnnouncementModalVisible(true));
  };

  return (
    <Header
      className="page-no-subscription-header"
      id="page-no-subscription-header"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
      }}
    >
      <div className="no-subscription-header-text">
        <span style={{ fontWeight: '400' }}>{text} </span>
      </div>
      <Button className="no-subscription-header-button" onClick={onClickGoToDetails}>
        {buttonText}
      </Button>
    </Header>
  );
};

export default AnnouncementHeader;
