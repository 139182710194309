/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  accesscodes: [],
  isLoading: false,
};

export const getAccessCodes = createAsyncThunk('accesscodes/getAccessCodes', async () => {
  return API.AccessCodesService.getAccessCodes();
});

export const addAccessCode = createAsyncThunk('accesscodes/addAccessCode', async payload => {
  return API.AccessCodesService.addAccessCode(payload);
});

const accessCodesSlice = createSlice({
  name: 'accesscodes',
  initialState,
  extraReducers: {
    [getAccessCodes.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAccessCodes.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać kodow dostepu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAccessCodes.fulfilled.toString()]: (state, action) => {
      state.accesscodes = action.payload.data;
      state.isLoading = false;
    },
    [addAccessCode.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addAccessCode.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać kodu dostepu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addAccessCode.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Dodano kod dostepu!',
        placement: 'bottomRight',
      });
    },
  },
});

export const { addSubmessageToPost } = accessCodesSlice.actions;

export default accessCodesSlice.reducer;
