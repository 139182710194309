import React from 'react';
import { Layout } from 'antd';
import './BlackFridayStyles.css';
import Countdown from './Countdown';

const BlackFridayHeader = () => {
  const { Header } = Layout;

  return (
    <Header
      className="page-no-subscription-header"
      id="page-no-subscription-header"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
      }}
    >
      <div id="no-subscription-header-text">
        <span style={{ fontWeight: '400' }}>
          🛍️ <b>BLACK WEEKEND:</b> -10% na wszystkie <b>pakiety</b> i <b>książki</b> z kodem “BLACKWEEKEND”.
        </span>
      </div>

      <div id="countdown-left-text-desktop">Kończy się za: </div>
      <Countdown />
    </Header>
  );
};

export default BlackFridayHeader;
