import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const Materials = lazy(() => import('../container/materials'));
const SingleSubject = lazy(() => import('../container/materials/overview/SingleSubject'));
const Sections = lazy(() => import('../container/materials/overview/Sections'));

const MaterialsRoutes = () => {
  const { path } = useRouteMatch();
  const { user } = useSelector(state => {
    return {
      user: state.user.user,
      courses: state.courses.courses,
      studentCourses: state.students.studentCourses,
    };
  });
  return (
    <Switch>
      <Route exact path={path} component={Materials} />
      <Route
        exact
        path={path}
        render={() =>
          (!user.isActiveSubscriptionPayment && user.paymentType === 'PACKAGES_V1') || user.role !== 'STUDENT' ? (
            <Materials />
          ) : (
            <Redirect to="/settings/profile" />
          )
        }
      />
      <Route exact path={`${path}/:materialId/sections`} component={Sections} />
      <Route path={`${path}/:materialId/sections/:sectionId/:subjectType/:subjectId`} component={SingleSubject} />
    </Switch>
  );
};

export default MaterialsRoutes;
