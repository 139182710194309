import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Rewards = lazy(() => import('../container/rewards/overview/Rewards'));

const RewardsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Rewards} />
    </Switch>
  );
};

export default RewardsRoutes;
