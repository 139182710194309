/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: true,
  materialVideos: [],
  materialVideosTags: [],
};

export const getMaterialVideos = createAsyncThunk(
  'materialVideos/getMaterialVideos',
  async ({ page, limit, search }) => {
    return API.MaterialVideosService.getMaterialVideos({ page, limit, search });
  },
);

export const addMaterialVideo = createAsyncThunk('materialVideos/addMaterialVideo', async payload => {
  return API.MaterialVideosService.addMaterialVideo(payload);
});

export const editMaterialVideo = createAsyncThunk('materialVideos/editMaterialVideo', async payload => {
  return API.MaterialVideosService.editMaterialVideo(payload);
});

export const getMaterialVideosTags = createAsyncThunk('materialVideos/getMaterialVideosTags', async search => {
  return API.MaterialVideosService.getMaterialVideosTags(search);
});

const materialVideosSlice = createSlice({
  name: 'materialVideos',
  initialState,
  reducers: {},
  extraReducers: {
    [getMaterialVideos.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.materialVideos = action.payload.data;
    },
    [getMaterialVideos.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({ message: 'Błąd', description: action.error.message });
    },
    [addMaterialVideo.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      notification.success({ message: 'Success', description: 'Video added successfully' });
    },
    [addMaterialVideo.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({ message: 'Error', description: action.error.message });
    },
    [editMaterialVideo.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      notification.success({ message: 'Success', description: 'Video edited successfully' });
    },
    [editMaterialVideo.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({ message: 'Error', description: action.error.message });
    },
    [getMaterialVideosTags.fulfilled.toString()]: (state, action) => {
      state.materialVideosTags = action.payload.data;
    },
    [getMaterialVideosTags.rejected.toString()]: (state, action) => {
      notification.error({ message: 'Błąd', description: action.error.message });
    },
  },
});

export const {} = materialVideosSlice.actions;

export default materialVideosSlice.reducer;
