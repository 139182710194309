import cookieCutter from 'cookie-cutter';

export const pageview = url => {
  const ref = cookieCutter.get('ref');
  let customMap = {};
  if (ref) {
    customMap = { ...customMap, ref };
  }
  window.gtag('config', process.env.REACT_APP_GA_PIXEL, {
    page_path: url,
    custom_map: customMap,
  });
};

// log specific events happening.
export const event = (action, params) => {
  window.gtag('event', action, params);
};
