import React, { lazy, Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserCartCoverSection from '../container/cart/overview/UserCartCoverSection.js';
import UserCartCoverSectionMobile from '../container/cart/overview/UserCartCoverSectionMobile.js';
import { SettingWrapper } from '../container/myProfile/styleSettings.js';
import { Main, PageHeaderStyle } from '../container/styled.js';
import { Cards } from '../components/cards/frame/cards-frame.js';

const Shop = lazy(() => import('../container/myProfile/Shop.js'));
const Orders = lazy(() => import('../container/cart/overview/OrdersNew.js'));
const CartRoutes = lazy(() => import('./cartNew.js'));
const ReferralCode = lazy(() => import('../container/myProfile/overview/ReferralCodePackages.js'));
const OrderWizard = lazy(() => import('../container/cart/overview/OrderWizardNew.js'));
const SummaryCard = lazy(() => import('../container/cart/overview/SummaryCard.js'));

function ShopRoutes() {
  const { path } = useRouteMatch();
  const { user } = useSelector(state => {
    return {
      user: state.user.user,
    };
  });

  return (
    <div id="shop-wrapper-id">
      <PageHeaderStyle className="my-account-header" id="header-shop-id" title="Sklep" />

      <Main>
        <Row gutter={25} className="desktop-my-account-content">
          <Col xxl={24} lg={24} md={24} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton avatar />
                  </Cards>
                }
              >
                <UserCartCoverSection />
              </Suspense>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton paragraph={{ rows: 20 }} />
                    </Cards>
                  }
                >
                  <Route
                    exact
                    path={path}
                    render={() => (user.paymentType === 'PACKAGES_V2' ? <Shop /> : <CartRoutes />)}
                  />
                  <Route
                    exact
                    path={`${path}/shop`}
                    render={() => (user.paymentType === 'PACKAGES_V2' ? <Shop /> : <CartRoutes />)}
                  />
                  <Route
                    exact
                    path={`${path}/cart`}
                    render={() =>
                      user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2' ? (
                        <CartRoutes />
                      ) : (
                        <Redirect to="/settings/profile" />
                      )
                    }
                  />
                  <Route exact path={`${path}/referral-code`} component={ReferralCode} />
                  <Route
                    exact
                    path={`${path}/orders`}
                    render={() =>
                      user.paymentType === 'PACKAGES_V1' ||
                      user.role !== 'STUDENT' ||
                      user.paymentType === 'PACKAGES_V2' ? (
                        <Orders />
                      ) : (
                        <Redirect to="/settings/profile" />
                      )
                    }
                  />
                  <Route exact path={`${path}/cart/checkout`} component={OrderWizard} />
                  <Route path={`${path}/checkout/:orderId/failed`} component={SummaryCard} />
                  <Route path={`${path}/cart/checkout/:orderId/finish`} component={OrderWizard} />
                  <Route path={`${path}/cart/checkout/:orderId`} component={OrderWizard} />
                  <Route path={`${path}/checkout/:orderId/successed`} component={SummaryCard} />
                </Suspense>
              </Switch>
            </SettingWrapper>
          </Col>
        </Row>
        <Row gutter={25} className="mobile-my-account-content-row">
          <Col md={24} xs={24} className="mobile-my-account-content-col">
            <SettingWrapper className="mobile-my-account-settngs-wrapper">
              <Col md={24} xs={24} className="author-box-col">
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton avatar />
                    </Cards>
                  }
                >
                  <UserCartCoverSectionMobile />
                </Suspense>
              </Col>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton paragraph={{ rows: 20 }} />
                    </Cards>
                  }
                >
                  <Route
                    exact
                    path={path}
                    render={() => (user.paymentType === 'PACKAGES_V2' ? <Shop /> : <CartRoutes />)}
                  />
                  <Route
                    exact
                    path={`${path}/shop`}
                    render={() => (user.paymentType === 'PACKAGES_V2' ? <Shop /> : <CartRoutes />)}
                  />
                  <Route
                    exact
                    path={`${path}/cart`}
                    render={() =>
                      user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2' ? (
                        <CartRoutes />
                      ) : (
                        <Redirect to="/settings/profile" />
                      )
                    }
                  />
                  <Route exact path={`${path}/referral-code`} component={ReferralCode} />
                  <Route
                    exact
                    path={`${path}/orders`}
                    render={() =>
                      user.paymentType === 'PACKAGES_V1' ||
                      user.role !== 'STUDENT' ||
                      user.paymentType === 'PACKAGES_V2' ? (
                        <Orders />
                      ) : (
                        <Redirect to="/settings/profile" />
                      )
                    }
                  />
                  <Route exact path={`${path}/cart/checkout`} component={OrderWizard} />
                  <Route path={`${path}/checkout/:orderId/failed`} component={SummaryCard} />
                  <Route path={`${path}/cart/checkout/:orderId/finish`} component={OrderWizard} />
                  <Route path={`${path}/cart/checkout/:orderId`} component={OrderWizard} />
                  <Route path={`${path}/checkout/:orderId/successed`} component={SummaryCard} />
                </Suspense>
              </Switch>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </div>
  );
}

export default ShopRoutes;
