import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { Avatar } from '../../../components/avatar/Avatar';
import Heading from '../../../components/heading/heading';
import { UserTableStyleWrapper } from '../../students/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { getAllCancelReasons } from '../../../ducks/subscriptions';
import { Badge, Button, Input, Statistic, Tag } from 'antd';

const CancelReasons = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');

  const { cancelReasons, isLoading } = useSelector(state => {
    return {
      cancelReasons: state.subscriptions.cancelReasons,
      isLoading: state.students.isLoading,
    };
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });

  const cancelReasonsTableColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Uzytkownik',
      dataIndex: 'user',
      key: 'user',
      render: student => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={student._id} firstName={student.firstName} lastName={student.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${student.firstName} ${student.lastName}`}
              </Heading>
              <span className="user-designation">{student.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Plan',
      dataIndex: 'subscriptionPlanDuringCancellation',
      key: 'subscriptionPlanDuringCancellation',
      render: plan => {
        return (
          <div className="user-info">
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${plan?.plan.amount / 100} ${plan?.plan.currency}`}
              </Heading>
              <span className="user-designation">{`${plan?.plan.interval_count} ${plan?.plan.interval}`}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Data kończa aktualnej fazy planu',
      dataIndex: 'subscriptionPlanDuringCancellation',
      key: 'subscriptionPlanDuringCancellation',
      render: plan => {
        return <span>{new Date(plan?.current_period_end * 1000).toLocaleString()}</span>;
      },
    },
    {
      title: 'Utworzono',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{new Date(createdAt).toLocaleString()}</span>;
      },
    },
  ];

  const handleSearch = value => {
    setSearch(value);
    dispatch(
      getAllCancelReasons({
        pageNo: pagination.current,
        limit: pagination.pageSize,
        search: value,
      }),
    );
  };

  const cancelReasonsTableData = cancelReasons.data?.map((reason, index) => {
    return {
      id: reason._id,
      index: index + 1 + (pagination.current - 1) * pagination.pageSize,
      user: reason.user,
      subscriptionPlanDuringCancellation: reason.subscriptionPlanDuringCancellation,
      cancellationReasons: reason.cancellationReasons,
      cancellationReasonDetails: reason.cancellationReasonDetails,
      createdAt: reason.createdAt,
    };
  });

  useEffect(() => {
    dispatch(
      getAllCancelReasons({
        pageNo: pagination.current,
        limit: pagination.pageSize,
        search: search,
      }),
    );
  }, [dispatch, pagination]);

  return (
    <>
      <PageHeader ghost title="Powody anulowania" />
      <Main>
        <Cards headless>
          <Input.Search
            placeholder="Wyszukaj po imieniu, nazwisku lub emailu użytkownika"
            enterButton
            onSearch={handleSearch}
            style={{ maxWidth: 400, marginBottom: 20 }}
          />
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                dataSource={cancelReasonsTableData}
                isLoading={isLoading}
                columns={cancelReasonsTableColumns}
                pagination={{
                  defaultPageSize: pagination.defaultPageSize,
                  total: cancelReasons.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} anulowań`,
                }}
                rowKey={record => record.id}
                onChange={pagin => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
                expandable={{
                  expandedRowRender: record => (
                    <div className="d-flex justify-content-between">
                      <div>
                        <Statistic title="Powody anulowania" value={record.cancellationReasons} />
                        <Statistic title="Szczegóły anulowania" value={record.cancellationReasonDetails} />
                      </div>
                      <div>
                        <Tag color="darkred">Anulowano</Tag>
                      </div>
                    </div>
                  ),
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default CancelReasons;
