import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Router, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import Recommendations from '../container/subscriptions/overview/Recommendations';
import CancelReasons from '../container/subscriptions/overview/CancelReasons';
import Refunds from '../container/subscriptions/overview/Refunds';

const Subscriptions = lazy(() => import('../container/subscriptions/index'));

const SubscriptionsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/manage-subscription`} component={Subscriptions} />
      <Route path={`${path}/recommendations`} component={Recommendations} />
      <Route path={`${path}/cancel-reasons`} component={CancelReasons} />
      <Route path = {`${path}/refunds`} component = {Refunds} />
    </Switch>
  );
};

export default SubscriptionsRoutes;
