import React, { useEffect, useCallback, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { UserTableStyleWrapper } from '../../students/style';
import { Avatar } from '../../../components/avatar/Avatar';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { getAllStudents, getStudents } from '../../../ducks/students';
import { Input, Col, Radio, Tag, Button, Space, Modal } from 'antd';
import moment from 'moment';
import { getAllNegativePoints } from '../../../ducks/materials';

const NegativePoints = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { negativePoints, user, isLoading } = useSelector(state => {
    return {
      isLoading: state.materials.isLoading,
      negativePoints: state.materials.negativePoints,
      user: state.user.user,
    };
  });
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });
  const [search, setSearch] = useState('');

  const negativePointsTableColumns = [
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      render: student => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={student._id} firstName={student.firstName} lastName={student.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${student.firstName} ${student.lastName}`}
              </Heading>
              <span className="user-designation">{student.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Negatywne punkty',
      dataIndex: 'subjectNegativePoint',
      key: 'subjectNegativePoint',
      render: subjectNegativePoint => {
        return (
          <figcaption>
            <Heading className="user-name" as="h6">
              {`${subjectNegativePoint.name} - ${subjectNegativePoint.negativePoints} punktów`}
            </Heading>
            <span className="user-designation">{subjectNegativePoint.description}</span>
          </figcaption>
        );
      },
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => {
        return <span>{date}</span>;
      },
    },
    {
      title: 'Przyznający',
      dataIndex: 'creator',
      key: 'creator',
      render: creator => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={creator._id} firstName={creator.firstName} lastName={creator.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${creator.firstName} ${creator.lastName}`}
              </Heading>
              <span className="user-designation">{creator.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Akcje',
      render: record => {
        return (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() =>
                onRedirect(
                  record.student._id,
                  record.assignedSubject.courseId,
                  record.section.materialId,
                  record.section._id,
                  record.subject._id,
                )
              }
            >
              Przejdź
            </Button>
          </Space>
        );
      },
    },
  ];

  const negativePointsTableData = negativePoints.data?.map(point => {
    return {
      key: point._id,
      student: point.user,
      creator: point.creator,
      section: point.section,
      subject: point.subject,
      assignedSubject: point.assignedSubject,
      subjectNegativePoint: point.subjectNegativePoint,
      createdAt: moment(point.createdAt).format('YYYY-MM-DD HH:mm'),
    };
  });

  useEffect(() => {
    dispatch(getAllNegativePoints({ page: pagination.current, limit: pagination.pageSize, search }));
  }, [dispatch, pagination]);

  const handleSearch = value => {
    setSearch(value);
    dispatch(getAllNegativePoints({ page: pagination.current, limit: pagination.pageSize, search: value }));
  };

  const onRedirect = (userId, courseId, materialId, sectionId, subjectId, subjectType) => {
    history.push(
      `/verify/${userId}/courses/${courseId}/materials/${materialId}/sections/${sectionId}/${subjectType}/${subjectId}`,
    );
  };

  return (
    <>
      <PageHeader ghost title="Lista ostatnio przyznanych ujemnych punktów" />
      <Main>
        <Cards headless>
          <Input.Search
            placeholder="Wyszukaj..."
            enterButton
            onSearch={handleSearch}
            style={{ maxWidth: 400, marginBottom: 20 }}
          />
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                loading={isLoading}
                dataSource={negativePointsTableData}
                columns={negativePointsTableColumns}
                // expandable={{
                //   expandedRowRender: record => (
                //     <p style={{ margin: 0 }}>
                //       <strong>Powód ostrzeżenia:</strong> {record.reason}
                //     </p>
                //   ),
                //   rowExpandable: record => record.reason !== '',
                // }}
                pagination={{
                  defaultPageSize: 10,
                  total: negativePoints.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} przyznanych negatywnych punktów`,
                }}
                onChange={(pagin, filters, sorter) => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default NegativePoints;
