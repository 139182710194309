import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Orders = lazy(() => import('../container/cart/overview/Orders'));
const OrderDetails = lazy(() => import('../container/cart/overview/OrderDetails'));

const OrdersRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Orders} />
      <Route exact path={`${path}/:orderId`} component={OrderDetails} />
    </Switch>
  );
};

export default OrdersRoutes;
