/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  rewards: [],
  isLoading: false,
  requestedRewards: [],
  collectedRewards: [],
};

export const addNewReward = createAsyncThunk('rewards/addNewReward', async payload => {
  return API.RewardsService.addReward(payload);
});

export const editReward = createAsyncThunk('rewards/editReward', async payload => {
  return API.RewardsService.editReward(payload);
});

export const getAllRewards = createAsyncThunk('rewards/getAllRewards', async () => {
  return API.RewardsService.getAllRewards();
});

export const getRewardsForSpecifiedCourse = createAsyncThunk('courses/addRewards', async payload => {
  const gettingRewardsForThisCourse = await API.CoursesService.getCourseRewards(payload);
  return gettingRewardsForThisCourse.data;
});

export const assignPoitsToCourse = createAsyncThunk('points', async payload => {
  return API.RewardsService.assignPointsToCourse(payload);
});

export const editPointsToCourse = createAsyncThunk('points/edit', async payload => {
  return API.RewardsService.editPointsToCourse(payload);
});

export const assignPointsForUserExcercise = createAsyncThunk('points/assignPoints', async payload => {
  return API.RewardsService.approveUserExcercise(payload);
});

export const assignRewardAsStudet = createAsyncThunk('rewards/assignRewardAsStudent', async payload => {
  return API.RewardsService.assignRewardAsStudet(payload);
});

export const getAllAssignedRewards = createAsyncThunk('rewards/getaAllAssignedRewards', async () => {
  return API.RewardsService.adminGetAllAssignedRewards();
});

export const adminChangeRequestedRewardsStatus = createAsyncThunk('rewards/adminChangeRewardStatus', async payload => {
  return API.RewardsService.adminChangeRequestedRewardsStatus(payload);
});

export const getStudentsReceivedRewards = createAsyncThunk('rewards/getStudentsReceivedRewards', async payload => {
  return API.RewardsService.getStudentsReceivedRewards(payload);
});

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: {
    [addNewReward.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addNewReward.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać nagrody',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addNewReward.fulfilled.toString()]: state => {
      state.isLoading = false;
    },
    [editReward.pending.toString()]: state => {
      state.isLoading = true;
    },
    [editReward.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się edytować nagrody',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editReward.fulfilled.toString()]: state => {
      state.isLoading = false;
    },
    [getAllRewards.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllRewards.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać wszystkich nagród',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllRewards.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.rewards = action.payload.data;
    },
    [assignPoitsToCourse.pending.toString()]: state => {
      state.isLoading = true;
    },
    [assignPoitsToCourse.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się przypisać punktów do kursu',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignPoitsToCourse.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Pomyslnie przypisano punkty za zadanie',
        placement: 'bottomRight',
      });
    },
    [editPointsToCourse.pending.toString()]: state => {
      state.isLoading = true;
    },
    [editPointsToCourse.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się edytować punktów za zadanie',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editPointsToCourse.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Pomyślnie edytowano punkty za zadanie',
        placement: 'bottomRight',
      });
    },
    [assignPointsForUserExcercise.pending.toString()]: state => {
      state.isLoading = true;
    },
    [assignPointsForUserExcercise.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się przyznać punktów za zadanie',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignPointsForUserExcercise.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Przyznano punkty za zadanie',
        placement: 'bottomRight',
      });
    },
    [assignRewardAsStudet.pending.toString()]: state => {
      state.isLoading = true;
    },
    [assignRewardAsStudet.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się odebrać nagrody.',
        description: `${
          action.error.message === 'Missing data'
            ? 'Brakujące dane'
            : action.error.message === 'Reward is comming soon'
            ? 'Nagroda dostępna wkrótce'
            : action.error.message === 'Reward is temporary disabled'
            ? 'Nagroda tymczasowo niedostępna'
            : action.error.message === 'Reward is not active'
            ? 'Nagroda nieaktywna'
            : action.error.message === 'Wrong reward'
            ? 'Niepoprawna nagroda'
            : action.error.message === 'Not enough points'
            ? 'Sprawdź ilość swoich punktów'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [assignRewardAsStudet.fulfilled.toString()]: state => {
      state.isLoading = false;
    },
    [getAllAssignedRewards.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllAssignedRewards.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać nagród zgłoszonych do odbioru',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllAssignedRewards.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.requestedRewards = action.payload.data;
    },
    [adminChangeRequestedRewardsStatus.pending.toString()]: state => {
      state.isLoading = true;
    },
    [adminChangeRequestedRewardsStatus.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się zaakceptować nagrody',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [adminChangeRequestedRewardsStatus.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Pomyślnie zmieniono status',
        placement: 'bottomRight',
      });
    },
    [getStudentsReceivedRewards.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentsReceivedRewards.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.collectedRewards = [];
      notification.error({
        meesage: 'Nie udało się pobrać odebranych nagród',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getStudentsReceivedRewards.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.collectedRewards = action.payload.data;
    },
  },
});

export default rewardsSlice.reducer;
