/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: false,
  warnings: [],
  warning: {},
};

export const addWarning = createAsyncThunk('warnings/addWarning', async payload => {
  return API.WarningsService.addWarning(payload);
});

export const getWarning = createAsyncThunk('warnings/getWarning', async ({ userId, subjectId }) => {
  return API.WarningsService.getWarning(userId, subjectId);
});

export const getAllWarnings = createAsyncThunk('warnings/getAllWarnings', async ({ page, limit, search }) => {
  return API.WarningsService.getAllWarnings(page, limit, search);
});

export const updateWarning = createAsyncThunk('warnings/updateWarning', async ({warningId, payload}) => {
  return API.WarningsService.updateWarning(warningId, payload);
});

const warningsSlice = createSlice({
  name: 'warnings',
  initialState,
  reducers: {},
  extraReducers: {
    [addWarning.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addWarning.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać ostrzeżenia',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addWarning.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Ostrzeżenie zostało dodane',
        placement: 'bottomRight',
      });
    },
    [getWarning.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getWarning.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać ostrzeżenia',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getWarning.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.warning = action.payload;
    },
    [getAllWarnings.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllWarnings.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać ostrzeżeń',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllWarnings.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.warnings = action.payload.data;
    },
    [updateWarning.pending.toString()]: state => {
      state.isLoading = true;
    },
    [updateWarning.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się zaktualizować ostrzeżenia',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [updateWarning.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Ostrzeżenie zostało zaktualizowane',
        placement: 'bottomRight',
      });
    },
  },
});

export default warningsSlice.reducer;
