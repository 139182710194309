// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getStudentOrders = () => APIService.get('orders');

export const getStudentsOrderDetails = orderId => APIService.get(`orders/${orderId}`);

export const returnOrder = orderId => APIService.post(`orders/${orderId}/return`);

export const getInvoicePdf = orderId => APIService.get(`orders/${orderId}/invoice`, { responseType: 'arraybuffer' });

export const createOrder = payload => APIService.post('orders', { ...payload });

export const getPaymentURL = payload => APIService.post('orders/payments', { ...payload });

export const getOrderStats = ({ startDate, endDate }) =>
  APIService.get(`orders/users/stats?startDate=${startDate}&endDate=${endDate}`);

export const getOrdersMonthlyStats = () => APIService.get(`orders/users/stats/monthly`);

export const getOrdersMonthlyPackagesStats = () => APIService.get(`orders/users/stats/monthly/packages`);

export const getOrdersYearlyPackagesStats = () => APIService.get(`orders/users/stats/yearly/packages`);

export const getIsUserFirstOrder = () => APIService.get('orders/first-order');
