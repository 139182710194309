import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { Avatar } from '../../../components/avatar/Avatar';
import Heading from '../../../components/heading/heading';
import { UserTableStyleWrapper } from '../../students/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { changeStatusOfRecommendationCode, getAllRecommendations, getRefunds } from '../../../ducks/subscriptions';
import { Badge, Button, Input, Statistic, Tag } from 'antd';

const Refunds = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');

  const { refunds } = useSelector(state => {
    return {
      refunds: state.subscriptions.refunds,
    };
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });

  const refundsTableColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Uzytkownik',
      dataIndex: 'user',
      key: 'user',
      render: student => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={student._id} firstName={student.firstName} lastName={student.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${student.firstName} ${student.lastName}`}
              </Heading>
              <span className="user-designation">{student.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => {
        return <div>{amount / 100} PLN</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 'pending':
            return <Badge status="warning" text="Oczekuje" />;
          case 'requires_action':
            return <Badge status="processing" text="Wymaga akcji" />;
          case 'succeeded':
            return <Badge status="success" text="Zakończono" />;
          case 'failed':
            return <Badge status="error" text="Niepowodzenie" />;
          case 'cancelled':
            return <Badge status="default" text="Anulowano" />;
          default:
            return <Badge status="default" text="Nieznany" />;
        }
      },
    },
    {
      title: 'Utworzono',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{new Date(createdAt).toLocaleString()}</span>;
      },
    },
  ];

  const handleSearch = value => {
    setSearch(value);
    setPagination({
      ...pagination,
      current: 1,
    });
    dispatch(
      getRefunds({
        pageNo: 1,
        limit: pagination.pageSize,
        search: value,
      }),
    );
  };

  const refundsTableData = refunds.data?.map((refund, index) => {
    return {
      id: refund._id,
      index: refund.refundNumber,
      user: refund.user,
      amount: refund.payload.amount,
      status: refund.payload.status,
      createdAt: refund.createdAt,
    };
  });

  useEffect(() => {
    dispatch(
      getRefunds({
        pageNo: pagination.current,
        limit: pagination.pageSize,
        search: search,
      }),
    );
  }, [dispatch, pagination]);

  return (
    <>
      <PageHeader ghost title="Zwroty" />
      <Main>
        <Cards headless>
          <Input.Search
            placeholder="Wyszukaj po imieniu, nazwisku lub emailu użytkownika"
            enterButton
            onSearch={handleSearch}
            style={{ maxWidth: 400, marginBottom: 20 }}
          />
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                dataSource={refundsTableData}
                columns={refundsTableColumns}
                pagination={{
                  defaultPageSize: pagination.defaultPageSize,
                  total: refunds?.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} zwrotów`,
                }}
                rowKey={record => record.id}
                onChange={pagin => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default Refunds;
