import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const QuizQuestions = lazy(() => import('../container/materials/overview/Quizquestions'));

const QuizQuestionsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={QuizQuestions} />
    </Switch>
  );
};

export default QuizQuestionsRoutes;
