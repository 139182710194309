import actions from './actions';

const initialState = {
  choosedSubscriptionPriceId: null,
  isSessionRecovery: false,
};

const ordersHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CHOSED_SUBSCRIPTION_PRICE_ID:
      return {
        ...state,
        choosedSubscriptionPriceId: action.choosedSubscriptionPriceId,
      };
    case actions.SET_IS_SESSION_RECOVERY:
      return {
        ...state,
        isSessionRecovery: action.isSessionRecovery,
      };
    default:
      return state;
  }
};

export default ordersHistoryReducer;
