/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const getTickets = () => APIService.get('tickets');

export const getTicketDetails = ticketId => APIService.get(`tickets/${ticketId}`);

export const getTicketComments = ticketId => APIService.get(`tickets/${ticketId}/comments`);

export const getTicketStatuses = ticketId => APIService.get(`tickets/${ticketId}/statuses`);

export const addTicket = payload => APIService.post('tickets', { ...payload });

export const adminAddTicket = payload => APIService.post('tickets/for-user', { ...payload });

export const addCommentToTicket = payload => APIService.post('tickets/comments', { ...payload });

export const changeTicketStatus = payload => APIService.post('tickets/statuses', { ...payload });

export const getStudentTickets = userID => APIService.get(`tickets/user/${userID}`);

export const getTicketCategories = () => APIService.get('tickets/categories');

export const addTicketCategory = payload => APIService.post('tickets/categories', { ...payload });

export const editTicketCategory = payload => APIService.put(`tickets/categories`, { ...payload });

export const changeTicketCategory = payload => APIService.put(`tickets/change-category`, { ...payload });

export const assignTeacherToTicket = payload => APIService.post('tickets/assign', { ...payload });
