/* eslint-disable import/no-cycle */
import { UnauthorizedService, APIService } from './ApiService';

// COURSES

export const getCourses = () => UnauthorizedService.get('courses');

export const addCourse = payload => APIService.post('courses', { ...payload });

export const editCourse = payload => APIService.put('courses', { ...payload });

export const assignLevelToCourse = payload => APIService.post('courses/levels', { ...payload });

export const assignStudentToCourse = payload =>
  UnauthorizedService.post(
    'student/courses/user/details',
    {
      courseId: payload.courseId,
      courseLevelId: payload.courseLevelId,
    },
    {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    },
  );

export const assignMaterialsToCourse = payload => APIService.post('courses/materials', { ...payload });

export const getCourseMaterials = courseId => APIService.get(`courses/${courseId}/materials`);

export const getCourseRewards = courseId => APIService.get(`rewards/courses/${courseId}`);

// ANNOUNCEMENTS

export const getAnnouncementsByCourseId = courseId => APIService.get(`announcements/${courseId}`);

export const addAnnouncement = payload => APIService.post('announcements', { ...payload });

export const addSurvey = payload => APIService.post('announcements/poll', { ...payload });

export const getSurveysByCourseId = courseId => APIService.get(`announcements/poll/${courseId}`);

export const addVote = payload => APIService.post('announcements/poll/vote', { ...payload });

export const hideSurvey = surveyId => APIService.put(`announcements/poll/${surveyId}/hide`);

export const unhideSurvey = surveyId => APIService.put(`announcements/poll/${surveyId}/unhide`);

// MESSAGES

export const getSubmessagesByMessageId = messageId => APIService.get(`messages/${messageId}/submessages`);

export const addMessage = payload => APIService.post('messages', { ...payload });

export const addSubmessage = payload => APIService.post('messages/submessages', { ...payload });

export const getCourse = courseId => APIService.get(`courses/${courseId}`);

export const getCourseModules = courseId => APIService.get(`courses/${courseId}/modules`);

export const addModule = payload => APIService.post('courses/modules', { ...payload });

export const addModuleRequirement = payload => APIService.post('courses/modules/requirements', { ...payload });

export const getModule = moduleId => APIService.get(`courses/modules/${moduleId}`);

export const editModule = payload => APIService.put(`courses/modules`, { ...payload });

export const removeRequirement = requirementId => APIService.delete(`courses/modules/requirements/${requirementId}`);

export const getCourseFaqs = ({ courseId, page, limit, search }) =>
  APIService.get(`courses/${courseId}/faqs?page=${page}&limit=${limit}&search=${search}`);

export const getCourseFaqGroups = courseId => APIService.get(`courses/${courseId}/faq-groups`);

export const addFaqGroup = (courseId, payload) => APIService.post(`courses/${courseId}/faq-groups`, payload);

export const editFaqGroup = (faqGroupId, courseId, payload) =>
  APIService.put(`courses/${courseId}/faq-groups/${faqGroupId}`, payload);

export const addFaq = (courseId, payload) => {
  APIService.post(`courses/${courseId}/faqs`, payload);
};

export const editFaq = (faqId, courseId, payload) => {
  APIService.put(`courses/${courseId}/faqs/${faqId}`, payload);
};

export const changeStudentCourse = payload => APIService.put('courses/change-student-course', { ...payload });

export const getCourseLevels = courseId => APIService.get(`courses/${courseId}/levels`);

export const getCourseNegativePoints = (courseId, page, limit, search) =>
  APIService.get(`materials/courses/${courseId}/negative-points?page=${page}&limit=${limit}&search=${search}`);

export const getAllCourseNegativePoints = courseId =>
  APIService.get(`materials/courses/${courseId}/negative-points/all`);

export const addCourseNegativePoints = payload => APIService.post('materials/negative-points', { ...payload });

export const assignNegativePoints = payload =>
  APIService.post('materials/users/subjects/negative-points', { ...payload });

export const editCourseNegativePoints = payload => APIService.put('materials/negative-points', { ...payload });
