import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const AssignedExercisesSettings = lazy(() => import('../container/materials/overview/AssignedExercisesSettings'));
const AssignedExercisesSettingsV2 = lazy(() => import('../container/materials/overview/AssignedExercisesSettingsV2'));

const AssignedExercisesSettingsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={AssignedExercisesSettings} />
      <Route exact path={`${path}/v2`} component={AssignedExercisesSettingsV2} />
    </Switch>
  );
};

export default AssignedExercisesSettingsRoutes;
