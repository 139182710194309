/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  subscriptions: [],
  subscriptionSchedules: [],
  subscriptionInvoices: [],
  features: [],
  isLoading: false,
};

export const getSubscriptions = createAsyncThunk(
  'subscriptions/getSubscriptions',
  async (page, limit, status, isCanceled, search, plan, sort, order) => {
    return API.SubscriptionsService.getSubscriptions(page, limit, status, isCanceled, search, plan, sort, order);
  },
);

export const getSubscriptionsSchedules = createAsyncThunk('subscriptions/getSubscriptionsSchedules', async () => {
  return API.SubscriptionsService.getSubscriptionsSchedules();
});

export const getSubscriptionInvoices = createAsyncThunk(
  'subscriptions/getSubscriptionInvoices',
  async ({ pageNo, limit, search }) => {
    return API.SubscriptionsService.getSubscriptionInvoices({ pageNo, limit, search });
  },
);
export const addFreeDaysToUser = createAsyncThunk('subscriptions/addFreeDaysToUser', async payload => {
  return API.SubscriptionsService.addFreeDaysToUser(payload);
});
export const getFeatures = createAsyncThunk('subscriptions/getFeatures', async () => {
  return API.SubscriptionsService.getFeatures();
});
const adminSubscriptionSlice = createSlice({
  name: 'adminsubscriptions',
  initialState,
  reducers: {},
  extraReducers: {
    [getSubscriptions.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSubscriptions.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać subskrypcji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSubscriptions.fulfilled.toString()]: (state, action) => {
      state.subscriptions = action.payload.data;
      state.isLoading = false;
    },
    [getSubscriptionsSchedules.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSubscriptionsSchedules.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać harmonogramów subskrypcji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSubscriptionsSchedules.fulfilled.toString()]: (state, action) => {
      state.subscriptionSchedules = action.payload.data;
      state.isLoading = false;
    },
    [getSubscriptionInvoices.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSubscriptionInvoices.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać faktur subskrypcji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSubscriptionInvoices.fulfilled.toString()]: (state, action) => {
      state.subscriptionInvoices = action.payload.data;
      state.isLoading = false;
    },
    [addFreeDaysToUser.fulfilled.toString()]: state => {
      notification.success({
        message: 'Darmowe dni zostały dodane do użytkownika!',
        placement: 'bottomRight',
      });
    },
    [addFreeDaysToUser.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać darmowych dni do użytkownika!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getFeatures.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getFeatures.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać funkcji subskrypcji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getFeatures.fulfilled.toString()]: (state, action) => {
      state.features = action.payload.data;
      state.isLoading = false;
    },
  },
});

export const { addSubmessageToPost } = adminSubscriptionSlice.actions;

export default adminSubscriptionSlice.reducer;
