/* eslint-disable import/no-cycle */
import * as APIService from './ApiService';
import * as AuthService from './AuthService';
import * as UserService from './UserService';
import * as MaterialsService from './MaterialsService';
import * as StudentsService from './StudentsService';
import * as CoursesService from './CoursesService';
import * as TicketsService from './TicketsService';
import * as CouponsService from './CouponsService';
import * as ProductsService from './ProductsService';
import * as CartService from './CartService';
import * as OrdersService from './OrdersService';
import * as ServerService from './ServerService';
import * as RewardsService from './RewardsService';
import * as QuizService from './QuizService';
import * as AccessCodesService from './AccessCodesService';
import * as UserTokensService from './UserTokensService';
import * as ShopService from './ShopService';
import * as SubscriptionsService from './SubscriptionsService';
import * as MultimediasService from './MultimediaService';
import * as MaterialVideosService from './MaterialVideosService';
import * as WarningsService from './WarningsService';
import * as RatingsService from './RatingsService';
import * as CrmService from './CrmService';
import * as QuestionsDatabaseService from './QuestionDatabaseService';

export default {
  APIService,
  AuthService,
  AccessCodesService,
  CrmService,
  UserTokensService,
  UserService,
  MaterialsService,
  StudentsService,
  CoursesService,
  TicketsService,
  CouponsService,
  ProductsService,
  CartService,
  OrdersService,
  ServerService,
  RewardsService,
  QuizService,
  SubscriptionsService,
  ShopService,
  MultimediasService,
  MaterialVideosService,
  WarningsService,
  RatingsService,
  QuestionsDatabaseService,
};
