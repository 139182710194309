import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ProtectedRoute from './components/utilities/protectedRoute';
import { CourseStore, CoursePersistor } from './ducks/store';
import Routes from './routes/index';
import Auth from './routes/auth';
import ErrorRoutes from './routes/errors';
import './static/css/style.css';
import config from './config/config';
import { getCoursesAsStudent } from './ducks/students';
import { getCourses } from './ducks/courses';
import * as ga from './utility/ga';
import { kmiTheme, kmmTheme } from './config/theme/kmiAndKmmThemes';
import GlobalColors from './static/css/GlobalColors';

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, accessToken, topMenu, darkMode, user, courses, studentCourses, isServerAlive } = useSelector(state => {
    return {
      darkMode: state.themeLayout.darkMode,
      rtl: state.themeLayout.rtl,
      topMenu: state.themeLayout.topMenu,
      accessToken: state.auth.accessToken,
      user: state.user.user,
      courses: state.courses.courses,
      studentCourses: state.students.studentCourses,
      isServerAlive: state.server.isServerAlive,
    };
  });
  const matchCourses = user && user.role === 'STUDENT' ? studentCourses : courses;

  const { theme } = config;
  const baseTheme = { rtl, topMenu, darkMode };
  const kmiOrKmmTheme = process.env.REACT_APP_THEME === 'KMM' ? kmmTheme : kmiTheme;
  const conectedThemes = { ...theme, ...baseTheme, ...kmiOrKmmTheme };

  useEffect(() => {
    if (accessToken !== null && user !== null) {
      if (user.role === 'STUDENT') {
        dispatch(getCoursesAsStudent());
      } else {
        dispatch(getCourses());
      }
    }
  }, [accessToken, dispatch, user]);

  useEffect(() => {
    if (matchCourses.length === 0 && isServerAlive && user && accessToken) {
      if (user.role === 'STUDENT') {
        dispatch(getCoursesAsStudent());
      } else {
        dispatch(getCourses());
      }
    }
  }, [accessToken, dispatch, isServerAlive, matchCourses.length, user]);

  useEffect(() => {
    import('react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL);
        ReactPixel.pageView();
        ReactPixel.trackCustom('VIEW_PLATFORM', {
          name: `VIEW_PLATFORM`,
        });
      });
    ga.pageview();
  }, []);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={conectedThemes}>
        <GlobalColors />
        <Router basename={process.env.PUBLIC_URL}>
          {!isServerAlive ? (
            <Route path="/" component={ErrorRoutes} />
          ) : !accessToken || !user ? (
            <Route path="/" component={Auth} />
          ) : (
            matchCourses.length > 0 && <ProtectedRoute path="" component={Routes} />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={CourseStore}>
      <PersistGate loading={null} persistor={CoursePersistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default hot(App);
