import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import Materials from './materials';
import ActiveSudentsWithoutSubscription from '../container/students/overview/ActiveStudentsWithoutSubscription';

const Students = lazy(() => import('../container/students'));
const StudentPanel = lazy(() => import('../container/students/overview/StudentPanel'));
const StudentsV2 = lazy(() => import('../container/students/overview/StudentsV2'));
const Warnings = lazy(() => import('../container/students/overview/StudentsWarnings'));

const StudentsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Students} />
      <Route exact path={`${path}/v2`} component={StudentsV2} />
      <Route exact path={`${path}/warnings`} component={Warnings} />
      <Route exact path={`${path}/activeaccountnosubscription`} component={ActiveSudentsWithoutSubscription} />
      <Route exact path={`${path}/:studentId`} component={StudentPanel} />
      <Route path={`${path}/:studentId/courses/:courseId/materials`} component={Materials} />
    </Switch>
  );
};

export default StudentsRoutes;
