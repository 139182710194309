/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: [],
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default modulesSlice.reducer;
