import { APIService } from './ApiService';

export const getCoupons = () => APIService.get('coupons');

export const archiveCoupon = payload => APIService.put('coupons/archive', { ...payload });

export const addCoupon = payload => APIService.post('coupons', { ...payload });

export const getUserReferralCodeCoupons = () => APIService.get('coupons/recommendation-code');

export const createUserReferralCodeCoupons = () => APIService.post('coupons/recommendation-code');

export const getUserUsedReferralCodesCoupons = ({ pageNo, limit }) =>
  APIService.get(`coupons/recommendation-code/used?page=${pageNo}&limit=${limit}`);

export const sendReferralCode = payload => APIService.post('coupons/recommendation-code/send-email', { ...payload });

export const changeStatusOfRecommendationCode = codeId =>
  APIService.post('coupons/recommendation-codes/change-status', { codeId });

export const getAllRecommendations = ({ pageNo, limit, search }) =>
  APIService.get(`coupons/recommendation-codes?page=${pageNo}&limit=${limit}&search=${search}`);
export const sendReferralCodeCoupons = payload =>
  APIService.post('coupons/recommendation-code/send-email', { ...payload });
