// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const addMultimedia = payload => APIService.post('files/multimedia', payload);

export const getMultimedias = ({ filesIds }) => APIService.get(`files/multimedia-files?ids=${filesIds}`);

export const getMultimediaDatas = ({ pageNo, limit, search }) =>
  APIService.get(`files/multimedias-data?page=${pageNo}&limit=${limit}&search=${search}`);

export const editMultimedia = (id, payload) => APIService.put(`files/multimedia/${id}`, payload);

export const getMultimediaTags = search => APIService.get(`files/multimedia-tags?search=${search}`);

export const getFile = fileId => APIService.get(`files/${fileId}`);
