import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { toggleSider } from '../redux/sider/actionCreator';

const BurgerButton = ({ toggleCollapsed, collapsed, id }) => {
  const dispatch = useDispatch();

  const handleToggle = () => {
    toggleCollapsed();
  };
  useEffect(() => {
    dispatch(toggleSider(collapsed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  return (
    <Button type="link" onClick={handleToggle} id={id}>
      <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" id="burger-icon" />
    </Button>
  );
};

export default BurgerButton;
