import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const StudentCrm = lazy(() => import('../container/crm/overview/StudentCrm'));
const StudentsV2 = lazy(() => import('../container/students/overview/StudentsV2'));
const Calendly = lazy(() => import('../container/crm/overview/Calendly'));
const AllTasks = lazy(() => import('../container/crm/overview/AllTasks'));
const UserTasks = lazy(() => import('../container/crm/overview/UserTasks'));
const NoPackagesUsers = lazy(() => import('../container/crm/overview/NoPackagesUsers'));

const CrmRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={StudentsV2} />
      <Route exact path={`${path}/calendly`} component={Calendly} />
      <Route exact path={`${path}/tasks`} component={AllTasks} />
      <Route exact path={`${path}/tasks/assigned`} component={UserTasks} />
      <Route exact path={`${path}/no-packages`} component={NoPackagesUsers} />
      <Route exact path={`${path}/student/:studentEmail`} component={StudentCrm} />
    </Switch>
  );
};

export default CrmRoutes;
