// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getStudentCarts = () => APIService.get('products/carts');

export const getCart = () => APIService.get('products/carts');

export const addToCart = payload => APIService.put('products/carts', { ...payload });

export const removeProductFromCart = payload => APIService.delete('products/carts', { data: { ...payload } });

export const setCartCoupon = payload => APIService.post('products/carts/coupons', { ...payload });

export const serCartRecommendationCode = payload =>
  APIService.post('products/carts/recommendation-code', { ...payload });
