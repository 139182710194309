import Styled from 'styled-components';

const AvatarStyled = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => size / 3}px;
  margin-right: 10px;
  color: white;
`;
const AvatarStyledEmpty = Styled.div`
          border: 2px solid ${({ theme }) => theme.avatarBorder};
            display: flex;
            border-radius: 50%;
            padding: 3px;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            width: ${({ size }) => size}px;
            height: ${({ size }) => size}px;
    
`;

const AvatarImageStyled = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => size / 3}px;
  margin-right: 10px;
  color: white;
`;

export { AvatarStyled, AvatarImageStyled, AvatarStyledEmpty };
