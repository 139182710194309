/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';
import { get } from 'js-cookie';

const initialState = {
  allSubscriptions: [],
  subscriptionSchedules: [],
  recommendations: [],
  refunds: [],
  cancelReasons: [],
  entitlements: [],
  incompleteSubscription: null,
  activePlan: null,
  isLoadedActivePlan: false,
  nextPaymentPhase: null,
  invoices: [],
  availablePlans: [],
  isUpdatingSubscriptionLoading: false,
  referralCode: null,
  usedReferralCodes: [],
  isSendingEmailLoading: false,
  isGeneratingUserCodeLoading: false,
  isLoadedNextPhase: false,
  areLoadedAvailablePlans: false,
  history: [],
  isSubscriptionChanged: false,
  amountRemaining: 0,
  prorationDate: 0,
};

export const startSubscription = createAsyncThunk('user/startSubscription', async payload => {
  return API.SubscriptionsService.startSubscription(payload);
});

export const recoverSubscription = createAsyncThunk('user/recoverSubscription', async payload => {
  return API.SubscriptionsService.recoverSubscription(payload);
});

export const updateCancelRecovery = createAsyncThunk('user/updateCancelRecovery', async payload => {
  return API.SubscriptionsService.updateCancelRecovery(payload);
});

export const updateCancelSubscription = createAsyncThunk('user/updateCancelSubscription', async payload => {
  return API.SubscriptionsService.updateCancelSubscription(payload);
});

export const updateAddSubscription = createAsyncThunk('user/updateAddSubscription', async payload => {
  return API.SubscriptionsService.updateAddSubscription(payload);
});

export const getRefunds = createAsyncThunk('user/getRefunds', async ({ pageNo, limit, search }) => {
  return API.SubscriptionsService.getRefunds(pageNo, limit, search);
});

export const updateCancelSubscriptionSchedule = createAsyncThunk(
  'user/updateCancelSubscriptionSchedule',
  async payload => {
    return API.SubscriptionsService.updateCancelSubscriptionSchedule(payload);
  },
);

export const updateAddSubscriptionSchedule = createAsyncThunk('user/updatedAddSubscriptionSchedule', async payload => {
  return API.SubscriptionsService.updateAddSubscriptionSchedule(payload);
});

export const updateResumeSubscriptionSchedule = createAsyncThunk('user/resumeSubscriptionSchedule', async payload => {
  return API.SubscriptionsService.updateResumeSubscriptionSchedule(payload);
});

export const updateRemoveSubscriptionSchedule = createAsyncThunk('user/removeSubscriptionSchedule', async payload => {
  return API.SubscriptionsService.updateRemoveSubscriptionSchedule(payload);
});

export const getUserSubscriptions = createAsyncThunk('user/getUserSubscriptions', async (pageNo, limit, status) => {
  return API.SubscriptionsService.getUserSubscriptions(pageNo, limit, status);
});

export const getUserSubscriptionSchedules = createAsyncThunk('user/getUserSubscriptionSchedules', async payload => {
  return API.SubscriptionsService.getUserSubscriptionSchedules(payload);
});

export const getUserActivePlan = createAsyncThunk('user/getUserActivePlan', async payload => {
  return API.SubscriptionsService.getUserActivePlan(payload);
});

export const getNextPaymentPhase = createAsyncThunk('user/getNextPaymentPhase', async payload => {
  return API.SubscriptionsService.getNextPaymentPhase(payload);
});

export const getUserIncompleteSubscription = createAsyncThunk('user/getUserIncompleteSubscription', async payload => {
  return API.SubscriptionsService.getUserIncompleteSubscription(payload);
});

export const cancelSubscription = createAsyncThunk('user/cancelSubscription', async () => {
  return API.SubscriptionsService.cancelSubscription();
});

export const cancelSubscriptionPostReason = createAsyncThunk('user/cancelSubscriptionPostReason', async payload => {
  return API.SubscriptionsService.cancelSubscriptionPostReason(payload);
});

export const createUserReferralCode = createAsyncThunk('user/createUserReferralCode', async () => {
  return API.SubscriptionsService.createUserReferralCode();
});

export const sendReferralCode = createAsyncThunk('user/sendReferralCode', async payload => {
  return API.SubscriptionsService.sendReferralCode(payload);
});

export const updatePayment = createAsyncThunk('user/updatePayment', async payload => {
  return API.SubscriptionsService.updatePayment(payload);
});

export const getUserInvoices = createAsyncThunk('user/getUserInvoices', async (pageNo, limit) => {
  return API.SubscriptionsService.getUserInvoices(pageNo, limit);
});
export const getAvailablePlans = createAsyncThunk('user/getAvailablePlans', async payload => {
  return API.SubscriptionsService.getAvailablePlans(payload);
});

export const getUserEntitlements = createAsyncThunk('user/getUserEntitlements', async () => {
  return API.SubscriptionsService.getUserEntitlements();
});

export const getUserReferralCode = createAsyncThunk('user/getUserReferralCode', async () => {
  return API.SubscriptionsService.getUserReferralCode();
});

export const getUserUsedReferralCodes = createAsyncThunk('user/getUserUsedReferralCodes', async (pageNo, limit) => {
  return API.SubscriptionsService.getUserUsedReferralCodes(pageNo, limit);
});
export const getAllRecommendations = createAsyncThunk(
  'user/getAllRecommendations',
  async ({ pageNo, limit, search }) => {
    return API.SubscriptionsService.getAllRecommendations({ pageNo, limit, search });
  },
);
export const getAllCancelReasons = createAsyncThunk('user/getCancelReasons', async ({ pageNo, limit, search }) => {
  return API.SubscriptionsService.getAllCancelReasons({ pageNo, limit, search });
});
export const changeStatusOfRecommendationCode = createAsyncThunk(
  'user/changeStatusOfRecommendationCode',
  async codeId => {
    return API.SubscriptionsService.changeStatusOfRecommendationCode(codeId);
  },
);
export const getSubscriptionHistory = createAsyncThunk('user/getSubscriptionHistory', async subscriptionId => {
  return API.SubscriptionsService.getSubscriptionHistory(subscriptionId);
});
const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  extraReducers: {
    resetSubscriptionValues: state => {
      state.amountRemaining = 0;
      state.prorationDate = 0;
    },
    [getUserSubscriptions.fulfilled.toString()]: (state, action) => {
      state.allSubscriptions = action.payload.data;
    },
    [getAllRecommendations.fulfilled.toString()]: (state, action) => {
      state.recommendations = action.payload.data;
    },
    [getAllCancelReasons.fulfilled.toString()]: (state, action) => {
      state.cancelReasons = action.payload.data;
    },
    [getUserActivePlan.fulfilled.toString()]: (state, action) => {
      state.isLoadedActivePlan = true;
      state.activePlan = action.payload.data;
    },
    [getUserSubscriptionSchedules.fulfilled.toString()]: (state, action) => {
      state.subscriptionSchedules = action.payload.data;
    },
    [getUserIncompleteSubscription.fulfilled.toString()]: (state, action) => {
      state.incompleteSubscription = action.payload.data;
    },
    [getNextPaymentPhase.fulfilled.toString()]: (state, action) => {
      state.isLoadedNextPhase = true;
      state.nextPaymentPhase = action.payload.data;
    },
    [getUserInvoices.fulfilled.toString()]: (state, action) => {
      state.invoices = action.payload.data;
    },
    [getAvailablePlans.fulfilled.toString()]: (state, action) => {
      state.areLoadedAvailablePlans = true;
      state.availablePlans = action.payload.data;
    },
    [getUserReferralCode.fulfilled.toString()]: (state, action) => {
      state.referralCode = action.payload.data;
    },
    [getUserUsedReferralCodes.fulfilled.toString()]: (state, action) => {
      state.usedReferralCodes = action.payload.data;
    },
    [updateAddSubscription.pending.toString()]: state => {
      state.isUpdatingSubscriptionLoading = true;
      notification.info({
        message: 'Trwa aktualizowanie subskrypcji!',
        placement: 'bottomRight',
      });
    },
    [updateAddSubscription.rejected.toString()]: (state, action) => {
      state.isUpdatingSubscriptionLoading = false;
      notification.error({
        message: 'Nie udało się zaktualizować subskrypcji!',
        description:
          action.error.message === 'Invalid timestamp'
            ? 'Niepoprawny zakres czasu'
            : action.error.message === 'Plan not found'
            ? 'Nie znaleziono planu'
            : action.error.message === 'Plan is free'
            ? 'Plan jest darmowy'
            : action.error.message === 'Cannot upgrade to different interval when upgrading'
            ? 'Nie można zmienić subskrypcji na inny okres'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [updateAddSubscription.fulfilled.toString()]: (state, action) => {
      state.isUpdatingSubscriptionLoading = false;
      state.isSubscriptionChanged = action.payload.data.isChanged;
      state.amountRemaining = action.payload.data.amountRemaining;
      state.prorationDate = action.payload.data.prorationDate;
      if (action.payload.isChanged) {
        notification.success({
          message: 'Subskrypcja zaktualizowana',
          placement: 'bottomRight',
        });
      }
    },
    [sendReferralCode.pending.toString()]: state => {
      state.isSendingEmailLoading = true;
    },
    [cancelSubscription.rejected.toString()]: (state, action) => {
      state.isSendingEmailLoading = false;
      state.sendEmailError = action.error.message;
      notification.error({
        message: 'Nie udało się anulować subskrypcji!',
        description:
          action.error.message === 'No active subscription found'
            ? 'Nie znaleziono aktywnej subskrypcji'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [sendReferralCode.rejected.toString()]: (state, action) => {
      state.isSendingEmailLoading = false;
      state.sendEmailError = action.error.message;
      notification.error({
        message: 'Nie udało wysłać wiadomości!',
        description:
          action.error.message === 'Email already sent'
            ? 'Kod został już wysłany na podany adres email'
            : action.error.message === 'Too many emails sent today'
            ? 'Możesz wysłać maksymalnie 10 emaili dziennie'
            : action.error.message === 'Email is required'
            ? 'Email jest wymagany'
            : action.error.message === 'User does not have a recommendation code'
            ? 'Uczeń nie posiada kodu polecającego'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [sendReferralCode.fulfilled.toString()]: state => {
      state.isSendingEmailLoading = false;
      notification.success({
        message: 'Wiadomość została wysłana',
        placement: 'bottomRight',
      });
    },
    [createUserReferralCode.pending.toString()]: state => {
      state.isGeneratingUserCodeLoading = true;
    },
    [createUserReferralCode.rejected.toString()]: (state, action) => {
      state.isGeneratingUserCodeLoading = false;
      notification.error({
        message: 'Nie udało wygenerować kodu!',
        description:
          action.error.message === 'User already has recommendation code'
            ? 'Kod został już raz wygenerowany!'
            : action.error.message,
        placement: 'bottomRight',
      });
    },
    [createUserReferralCode.fulfilled.toString()]: state => {
      state.isGeneratingUserCodeLoading = false;
      state.sendEmailSuccess = true;
      notification.success({
        message: 'Kod został wygenerowany pomyślnie',
        placement: 'bottomRight',
      });
    },
    [getUserEntitlements.fulfilled.toString()]: (state, action) => {
      state.entitlements = action.payload.data;
    },
    [getUserEntitlements.rejected.toString()]: (state, action) => {
      state.entitlements = [];
      notification.error({
        message: 'Nie udało się pobrać uprawnień',
        description: action.error.message,
        placement: 'bottomRight',
      });
    },
    [getSubscriptionHistory.fulfilled.toString()]: (state, action) => {
      state.history = action.payload.data;
    },
    [getSubscriptionHistory.rejected.toString()]: (state, action) => {
      state.history = [];
      // notification.error({
      //   message: 'Nie udało się pobrać historii subskrypcji',
      //   description: action.error.message,
      //   placement: 'bottomRight',
      // });
    },
    [getSubscriptionHistory.pending.toString()]: state => {
      state.history = [];
    },
    [getRefunds.fulfilled.toString()]: (state, action) => {
      state.refunds = action.payload.data;
    },
    [getRefunds.rejected.toString()]: (state, action) => {
      state.refunds = [];
      notification.error({
        message: 'Nie udało się pobrać zwrotów',
        description: action.error.message,
        placement: 'bottomRight',
      });
    },
  },
});

export default subscriptionsSlice.reducer;
