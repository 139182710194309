import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Button, Input, Modal, Switch } from 'antd';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import moment from 'moment';
import { UserTableStyleWrapper } from '../../students/style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { addTicketCategory, editTicketCategory, getTicketCategories } from '../../../ducks/tickets';

const TicketCategories = () => {
  const dispatch = useDispatch();

  const [isAddModalVisible, setAddModalVisibility] = useState(false);
  const [isEditModalVisible, setEditModalVisibility] = useState(false);
  const [text, setText] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const { user, ticketCategories, isTicketCategoriesLoading } = useSelector(state => {
    return {
      user: state.user.user,
      ticketCategories: state.tickets.ticketCategories,
      isLoading: state.tickets.isTicketCategoriesLoading,
    };
  });

  const ticketCategoriesTableColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Kategoria',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Czy kategoria jest widoczna dla użytkowników?',
      dataIndex: 'isVisible',
      key: 'isVisible',
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</span>;
      },
    },
    {
      title: 'Akcje',
      dataIndex: 'index',
      key: 'index',
      render: index => {
        const record = ticketCategories[index - 1];
        return (
          <Button
            onClick={() => {
              setText(record.text);
              setIsVisible(record.isVisible);
              setEditModalVisibility(true);
              setTicketId(record._id);
            }}
          >
            Edytuj
          </Button>
        );
      },
    },
  ];

  const ticketCategoriesTableData = ticketCategories?.map((category, index) => {
    return {
      key: category._id,
      text: category.text,
      index: index + 1,
      isVisible: category.isVisible ? 'Tak' : 'Nie',
      createdAt: category.createdAt,
    };
  });

  const handleAddCategory = async () => {
    await dispatch(addTicketCategory({ text, isVisible }));
    dispatch(getTicketCategories());
    setAddModalVisibility(false);
  };

  const handleEditCategory = async () => {
    await dispatch(editTicketCategory({ text, isVisible, categoryId: ticketId }));
    dispatch(getTicketCategories());
    setEditModalVisibility(false);
  };

  useEffect(() => {
    dispatch(getTicketCategories());
  }, [dispatch]);

  return (
    <>
      <PageHeader
        ghost
        title="Kategorie zgłoszeń"
        buttons={
          <>
            <Button
              size="default"
              type="primary"
              key="course"
              onClick={() => {
                setText('');
                setIsVisible(true);
                setAddModalVisibility(true);
              }}
            >
              <FeatherIcon icon="plus" size={14} />
              Dodaj kategorię
            </Button>
          </>
        }
      />
      <Main>
        <Cards headless>
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                dataSource={ticketCategoriesTableData}
                columns={ticketCategoriesTableColumns}
                isLoading={isTicketCategoriesLoading}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
      <Modal
        title="Dodaj kategorię"
        visible={isAddModalVisible}
        onOk={handleAddCategory}
        onCancel={() => setAddModalVisibility(false)}
      >
        <div className="form-group">Nazwa kategorii</div>
        <Input
          type="text"
          className="form-control"
          placeholder="Nazwa kategorii"
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <div className="form-group">Czy kategoria jest widzoczna?</div>
        <Switch checked={isVisible} onChange={e => setIsVisible(e)} aria-label="Czy widoczne?" />
      </Modal>
      <Modal
        title="Edytuj kategorię"
        visible={isEditModalVisible}
        onOk={handleEditCategory}
        onCancel={() => setEditModalVisibility(false)}
      >
        <div className="form-group">Nazwa kategorii</div>

        <Input
          type="text"
          className="form-control"
          placeholder="Nazwa kategorii"
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <div className="form-group">Czy kategoria jest widzoczna?</div>

        <Switch checked={isVisible} onChange={e => setIsVisible(e)} aria-label="Czy widoczne?" />
      </Modal>
    </>
  );
};

export default TicketCategories;
