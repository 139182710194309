import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import TicketCategories from '../container/tickets/overview/TicketCategories';

const Tickets = lazy(() => import('../container/tickets'));

const TicketsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Tickets} />
      <Route path={`${path}/categories`} component={TicketCategories} />
    </Switch>
  );
};

export default TicketsRoutes;
