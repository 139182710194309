import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Layout } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { getUserSubscriptions, getSubscriptionHistory, getUserActivePlan } from '../ducks/subscriptions';

const NoSubscriptionHeader = ({ activePlan, subscriptionsHistory, allSubscriptions, user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionId = activePlan?.id;

  const { Header } = Layout;

  const onClickGoToManageSubscription = () => {
    history.push('/settings/subscriptions/manage-subscription');
  };

  const sortedSubscriptionsHistory = subscriptionsHistory
    ? [...subscriptionsHistory]?.sort((a, b) => b?.period_end - a?.period_end)
    : [];
  const hasUserSubscriptionBefore = allSubscriptions
    ? allSubscriptions.data?.filter(sub => sub.status !== 'incomplete' && sub.status !== 'incomplete_expired')
    : [];

  const loadUserSubscriptions = async () => {
    await dispatch(getUserSubscriptions({ pageNo: 1, pageSize: 10, status: 'all' }));
    await dispatch(getSubscriptionHistory({ subscriptionId }));
    await dispatch(getUserActivePlan());
  };
  useEffect(() => {
    if (user.paymentType === 'SUBSCRIPTIONS_V1') {
      loadUserSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header
      className="page-no-subscription-header"
      id="page-no-subscription-header"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
      }}
    >
      <div className="no-subscription-header-text">
        {activePlan?.status === 'past_due' && user.isActiveSubscription ? (
          <>
            <FeatherIcon
              icon="alert-circle"
              strokeWidth={2}
              color="#FFFFFF"
              size={20}
              style={{ marginRight: '5px', marginBottom: '-5px' }}
            />
            <span className="bold-no-sub-header-text">Ups, mamy problem!</span>{' '}
            <span style={{ fontWeight: '400' }}>
              Nie możemy przetworzyć płatności. Dostęp do Twojego konta wkrótce wygaśnie
            </span>
          </>
        ) : (sortedSubscriptionsHistory?.length && sortedSubscriptionsHistory?.[0]?.status !== 'unpaid') ||
          (hasUserSubscriptionBefore?.length && !user.isActiveSubscription) ||
          user?.hasUserBoughtAnySubscription ? (
          <>
            <span className="bold-no-sub-header-text">Twoje konto jest nieaktywne.</span>{' '}
            <span style={{ fontWeight: '400' }}> Uzyskaj ponownie dostęp</span>
          </>
        ) : (
          <>
            <span style={{ fontWeight: '400' }}> Matura 2025? Zarezerwuj swoje miejsce w kursie! </span>
            <span style={{ fontWeight: '400' }} className="no-subscription-header-text available-number">
              Liczba miejsc ograniczona
            </span>
          </>
        )}
      </div>
      <Button className="no-subscription-header-button" onClick={onClickGoToManageSubscription}>
        Zarządzaj płatnościami
      </Button>
    </Header>
  );
};

export default NoSubscriptionHeader;
