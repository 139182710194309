import Styled from 'styled-components';

const UserTableStyleWrapper = Styled.nav`
  table{
    tr{
        th{
          &:last-child{
            text-align: left !important;
          }
        }
      }
    tbody{
      tr{
          cursor: pointer;
        }
      td{
        .user-info{
          .user-name{
            font-size: 14px;
          }
        }
        span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: #ddd;
          &.Aktywny{
            background-color: ${({ theme }) => theme['success-color']}15;
            color: ${({ theme }) => theme['success-color']};
          }
          &.Nieaktywny{
            background-color: ${({ theme }) => theme['warning-color']}15;
            color: ${({ theme }) => theme['warning-color']};
          }
          &.blocked{
            background-color: ${({ theme }) => theme['danger-color']}15;
            color: ${({ theme }) => theme['danger-color']};
          }
        }
      }
    }
  }
  .ant-table-cell
  .ant-table-pagination.ant-pagination{
    width: 100%;
    text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
    border-top: 1px solid ${({ theme }) => theme['border-color-light']};
    margin-top: 0 !important;
    padding-top: 30px;
    @media only screen and (max-width: 767px){
      text-align: center;
    }
  }
  }
`;

const UserProfileDataWrapper = Styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .user-name{
    margin-top: 10px;
  }
`;

const CourseCardWrapper = Styled.div`
cursor: pointer;

.card-row {
  display: flex;
  flex-direction: column;
  width: 50%;
  .header-text {
    font-weight: 500;
    font-size: 16px;
  }
  .description-text {
    color: #5A5F7D;
  }
}
span.status-text{
  font-size: 12px;
  padding: 0 12.41px;
  line-height: 1.9;
  font-weight: 500;
  border-radius: 12px;
  text-transform: capitalize;
  display: inline-block !important;
  background: #ddd;
  &.Aktywny{
    background-color: ${({ theme }) => theme['success-color']}15;
    color: ${({ theme }) => theme['success-color']};
  }
  &.Nieaktywny{
    background-color: ${({ theme }) => theme['warning-color']}15;
    color: ${({ theme }) => theme['warning-color']};
  }
  &.blocked{
    background-color: ${({ theme }) => theme['danger-color']}15;
    color: ${({ theme }) => theme['danger-color']};
  }
}

`;

export { UserTableStyleWrapper, UserProfileDataWrapper, CourseCardWrapper };
