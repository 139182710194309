import Styled from 'styled-components';

const getBackgroundImagePath = (folder, imageName) => {
  return require(`../../../../static/img/${folder}/${imageName}.png`);
};
const Aside = Styled.aside`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-image: 
  url(${getBackgroundImagePath(process.env.REACT_APP_BACKGROUND_IMAGES_DIR, 'logowanie-web')});
   background-repeat: no-repeat;
  background-attachment: local;
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 1024px){
    background-image: none;
    display: none;

  }
  @media only screen and (max-width: 767px){
    height: 100%;
  }
  .topShape {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
  }
  .bottomShape {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .auth-side-content{
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
  .auth-header{
    font-weight: 300;
    color: #fff;
  }
`;

const Content = Styled.div`
    padding: 100px;
    @media only screen and (max-width: 1599px){
      padding: 50px;
    }
    @media only screen and (max-width: 991px){
      padding: 20px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthWrapper = Styled.div`
.asterisk {color: ${({ theme }) => theme['error-color']}; padding-left: 4px }

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1599px){
    padding: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px){
        width: 50%;
        }  

  @media only screen and (max-width: 991px){
    padding: 0;
  }
    
  @media only screen and (max-width: 767px){
    text-align: center;
    display: flex;
    padding: 0;
    padding-top: 10rem;
    height: 100% !important;
    width: 80% !important;
  }
  @media only screen and (max-width: 455px){
    text-align: center;
    display: flex;
    padding: 0;
    padding-top: 10rem;
    height: 100% !important;
    width: 100% !important;
  }
  
  .background-image {
    @media only screen and (max-width: 1024px){
    display: flex;
    position: absolute;
    height: 25%;
    width: 100%;
    top: 0;
    background-image: 
    url(${getBackgroundImagePath(process.env.REACT_APP_BACKGROUND_IMAGES_DIR, 'logowanie-tab')});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
  }
    @media only screen and (max-width: 767px){
    display: flex;
    position: absolute;
    height: 25%;
    width: 100%;
    top: 0;
    background-image: 
    url(${getBackgroundImagePath(process.env.REACT_APP_BACKGROUND_IMAGES_DIR, 'logowanie-tab')});
     background-size: cover; 
    background-repeat: no-repeat;
    
  }

  @media only screen and (max-width: 468px){
    display: flex;
    position: absolute;
    height: 25%;
    width: 100%;
    top: 0;
    background-image: 
    url(${getBackgroundImagePath(process.env.REACT_APP_BACKGROUND_IMAGES_DIR, 'logowanie-mob')});
     background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
  }
  }
  .auth-notice{
    position: absolute;
    bottom: 50px;
    right: 50px;
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .link-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 499px){
      justify-content: start;
    }
  }
  .auth-link {
    font-weight: bold;
    padding-left: 1px; 
    font-weight: normal;
    font-size: 15px; 
    color: #061A2C;
    @media only screen and (max-width: 1024px){
      display: flex;
      flex-direction: row;
      font-size: 15px !important;
    }
    @media only screen and (max-width: 498px){
      display: flex;
      flex-direction: column;
    }
  }
  button{
    &.btn-signin{
      min-width: 185px;
    }
    &.btn-create{
      border-radius: 8px;
      min-width: 205px;
    }
    &.btn-reset{
      border-radius: 8px;
      min-width: 260px;
    }
    &.ant-btn-lg{
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
  }
  .auth-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 333px;
    max-width: 333px;
    height: 100%;
    .auth-contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      input::placeholder{
        color: ${({ theme }) => theme['extra-light-color']};
      }
        @media only screen and (max-width: 455px){
          width: 333px !important;
          max-width: 333px !important;
        } 
        @media only screen and (max-width: 385px){
        width: 310px !important;
    max-width: 310px !important;
    }
    @media only screen and (max-width: 320px){
        width: 300px !important;
    max-width: 300px !important;
    }
    .login-email-error{
    /* color: #EB788D !important; */
    color: #f5222d !important;
    font-size: 11px !important !important;
    font-weight: 400 !important;
    margin-top: 3px !important;
    text-align: start !important;
}
    }

      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
      .ant-input {
        border-color: #D7DBDE;
        height: 43px;
        font-size: 16px;
        &:hover {
          border-color: ${({ theme }) => theme.primaryButtonsColor};
        }
        @media only screen and (max-width: 455px){
         height: 40px;
        }
      }
      #email-input {
        &:focus {
          box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
        }
      }
      .ant-input-affix-wrapper{
        border: 1px solid #D7DBDE !important;
        border-radius: 5px;
        height: 43px;
        &:hover {
          border-color:  ${({ theme }) => theme.primaryButtonsColor};
        }
        @media only screen and (min-width: 1025px){
         padding: 0px 11px;
        }
        @media only screen and (max-width: 1024px){
          padding: 0px 11px;
        }
        @media only screen and (max-width: 768px){
          padding: 0px 11px;
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    .form-divider{
      font-size: 13px;
      color: ${({ theme }) => theme['gray-solid']};
      text-align: center;
      position: relative;
      margin-bottom: 25px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['border-color-light']};
      }
      span{
        background: #fff;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
      }
    }
    .social-login{
      display: flex;
      align-items: center;
      margin: -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            box-shadow: 0 5px 15px ${({ theme }) => theme['light-color']}10;
            background-color: #fff;
          }
        }
      }
      li{
        padding:6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          background: ${({ theme }) => theme['bg-color-light']};
          color: ${({ theme }) => theme['text-color']};
          font-weight: 500;
          @media only screen and (max-width: 379px){
            height: 44px;
            padding: 0 12px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-signup,
          &.google-signin{
            display: flex;
            align-items: center;
            padding: 0 30px;
            @media only screen and (max-width: 379px){
              padding: 0 5px;
            }
            img{
              margin-right: 8px;
              @media only screen and (max-width: 379px){
                margin-right: 4px;
              }
            }
          }
          &.facebook-sign{
            color: #475993;
          }
          &.twitter-sign{
            color: #03A9F4;
          }
        }
      }
    }
    #password-input-login {
      background: transparent;      
    }
.placeholder-italic-password-input .ant-input::placeholder {
  font-style: italic !important;
  font-size: 16px;
             @media only screen and (max-width: 455px){
               font-size: 15px;
              }
}
.placeholder-italic-login-input::placeholder {
  font-style: italic !important;
  font-size: 16px;
             @media only screen and (max-width: 455px){
               font-size: 15px;
              }
}
.ant-input-affix-wrapper>input.ant-input {
  padding-left: 2px;
}
.ant-input-password-icon {
  color: #A9A9A9 !important;
}
.button-loggin {
border: 0;
width: 100%;
border-radius: 10px;
font-size: 16px !important;
background-color: ${({ theme }) => theme.primaryButtonsColor};
border: none !important;
font-weight: bold;
margin-top: 21px;
margin-bottom: 20px;
height: 44px !important;
color: #fff !important;
&:hover {
  border: none !important;
  background-color: ${({ theme }) => theme.hoverButtonsColor};
  color: #fff !important;
}
&:focus{
  background-color: ${({ theme }) => theme.primaryButtonsColor};
  border: none !important;
}
@media only screen and (max-width: 1024px){
      font-size: 16px !important;
      height: 43px !important;
      margin-top: 25px !important;;
      margin-bottom: 21px !important;;
    }
    @media only screen and (max-width: 468px){
      height: 40px !important;
      font-size: 15px !important;
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
}
.button-loggin:disabled{
  background-color: ${({ theme }) => theme.primaryButtonsColor} !important;
}
.button-loggin:disabled:hover{
  background-color: ${({ theme }) => theme.hoverButtonsColor} !important;
}
.hover-text {
  margin-left: 7px;
  color: rgb(6, 26, 44) !important;
    
  &:hover {
    color:  ${({ theme }) => theme.primaryButtonsColor} !important;
}
}
.sign-in-main-col { display: flex; flex-direction: column; width: 100%; justify-content: center; align-items: center;
  @media only screen and (min-width: 992px){
      margin-left: 0px 
    }
  @media only screen and (max-width: 1024px){
      margin-left: 0px 
      margin-top: 210px;
    }
  @media only screen and (max-width: 767px){
      margin-top: 37px;
      margin-left: 0px 
    }
    @media only screen and (max-width: 425px){
      width: 100%;
      margin-left: 0px 
    }
}
.sign-in-header-text {
    font-weight: bold; font-size: 23px; color: #061A2C; margin: 0;
    @media only screen and (max-width: 1024px){
      font-size: 23px;
      margin: 0;
    }
    @media only screen and (max-width: 991px){
      margin: 0;
    }
    @media only screen and (max-width: 455px){
      font-size: 18px;
    }
}
.header-div { display: flex; width: 100%; justify-content: start; margin-bottom: 19px; 
  @media only screen and (max-width: 455px){
    margin-bottom: 10px; 
    }
}
.input-label {display: flex; margin-top: 20px; font-weight: 500; margin-bottom: 5px; color: #232529; font-size: 16px;
  @media only screen and (max-width: 1024px){
      display: flex;
      width: 100%;
      font-size: 16px;
    }
  @media only screen and (max-width: 767px){
      display: flex;
      width: 100%;
    }
    @media only screen and (max-width: 468px){
      font-size: 15px;
      margin-bottom: 6px;
    }
},
`;

export { Aside, Content, AuthWrapper };
