import React, { useCallback, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { AvatarStyled, AvatarImageStyled } from './style';
import { NoImageAvatar } from './NoImageAvatar';

const Avatar = forwardRef((props, ref) => {
  const { size, userId, topbarAvatar, className, lock, lockIconStyle, firstName, lastName } = props;
  const [image, setImage] = useState(null);
  const [avatarCache, setAvatarCache] = useState(moment().format());

  useImperativeHandle(ref, () => ({
    restartAvatarCache() {
      setAvatarCache(moment().format());
    },
  }));

  useEffect(() => {
    const imagePath = `${process.env.REACT_APP_API_URL}users/${props.userId}/v2/avatar`;
    axios
      .get(imagePath)
      .then(result => {
        if (result?.data !== 'OK') {
          setImage(imagePath);
        } else {
          setImage(null);
        }
      })
      .catch(() => {});
  }, [props.userId, avatarCache]);

  const getTwoLastCharsFromId = useCallback(id => {
    if (id) {
      const firstChar = id[id.length - 2];
      const secondChar = id[id.length - 1];

      return [firstChar, secondChar];
    }
    return [' ', ' '];
  }, []);

  const getFirstCharInUpperCase = useCallback(text => {
    if (text) {
      return text[0].toUpperCase();
    }
    return '';
  });

  const getLastTwoCharsSum = useCallback(() => {
    const lastTwoChars = getTwoLastCharsFromId(userId);
    const firstCharIntValue = lastTwoChars[0].charCodeAt(0);
    const secondCharIntValue = lastTwoChars[1].charCodeAt(0);

    return firstCharIntValue + secondCharIntValue;
  }, [userId, getTwoLastCharsFromId]);

  const getColor = useCallback(() => {
    const avatarBackgroundColorIndex = getLastTwoCharsSum() % 4;

    switch (avatarBackgroundColorIndex) {
      case 0:
        return 'rgb(255, 191, 0)';
      case 1:
        return 'rgb(0, 162, 174)';
      case 2:
        return 'rgb(245, 106, 0)';
      case 3:
        return 'rgb(114, 101, 230)';
      default:
        return 'rgb(255, 255, 255)';
    }
  }, [getLastTwoCharsSum]);

  return lock ? (
    <FeatherIcon height={15} icon="lock" style={lockIconStyle} />
  ) : !image && !topbarAvatar ? (
    <div>
      <AvatarStyled key={userId} backgroundColor={getColor()} size={size} className={className}>
        {getFirstCharInUpperCase(firstName)}
        {getFirstCharInUpperCase(lastName)}
      </AvatarStyled>
    </div>
  ) : !image && topbarAvatar ? (
    <NoImageAvatar />
  ) : (
    <AvatarImageStyled key={userId} backgroundColor={getColor()} size={size} className={className}>
      <img
        key={`${avatarCache}-${userId}`}
        src={image}
        width={size}
        height={size}
        style={{
          marginRight: 0,
          width: size,
          height: size,
          border: `2px solid ${getColor()}`,
          borderRadius: '50%',
        }}
        alt={userId}
      />
    </AvatarImageStyled>
  );
});

Avatar.defaultProps = {
  size: 40,
};

Avatar.propTypes = {
  size: PropTypes.number,
};

export { Avatar };
