const actions = {
  SET_CHOSED_SUBSCRIPTION_PRICE_ID: 'SET_CHOSED_SUBSCRIPTION_PRICE_ID',
  SET_IS_SESSION_RECOVERY: 'SET_IS_SESSION_RECOVERY',

  setChosedSubscriptionPriceId: choosedSubscriptionPriceId => ({
    type: actions.SET_CHOSED_SUBSCRIPTION_PRICE_ID,
    choosedSubscriptionPriceId,
  }),

  setIsSessionRecoveryAction: isSessionRecovery => ({
    type: actions.SET_IS_SESSION_RECOVERY,
    isSessionRecovery,
  }),
};

export default actions;
