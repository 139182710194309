import { createGlobalStyle } from 'styled-components';

const GlobalColors = createGlobalStyle`
:root {
--disabled-buttons-color: ${({ theme }) => theme['--disabled-buttons-color']};
--primary-buttons-color: ${({ theme }) => theme['--primary-buttons-color']};
--inputs-focus-shadow-color: ${({ theme }) => theme['--inputs-focus-shadow-color']};
--hover-buttons-color: ${({ theme }) => theme['--hover-buttons-color']};
--my-profile-navlink-hover: ${({ theme }) => theme['--my-profile-navlink-hover']};
--avatar-border: ${({ theme }) => theme['--avatar-border']};
--send-anser-materials-disabled-color: ${({ theme }) => theme['--send-anser-materials-disabled-color']};
--register-buttons-border-radius: ${({ theme }) => theme['--register-buttons-border-radius']};
--hover-report-error-buttons: ${({ theme }) => theme['--hover-report-error-buttons']};
--editor-buttons-hover: ${({ theme }) => theme['--editor-buttons-hover']};
}

`;

export default GlobalColors;
