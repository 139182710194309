import { APIService, APIServiceAllRoles, APIServiceHealthCheck, UnauthorizedService } from './ApiService';

export const startSubscription = payload => APIService.post('subscriptions/start-subscription', { ...payload });

export const recoverSubscription = payload => APIService.post('subscriptions/recover-subscription', { ...payload });

export const updateCancelRecovery = payload => APIService.post('subscriptions/cancel-recovery', { ...payload });

export const updateCancelSubscription = payload => APIService.post('subscriptions/update/cancel', { ...payload });

export const updateAddSubscription = payload => APIService.post('subscriptions/update/add', { ...payload });

export const getRefunds = (pageNo, limit, search) =>
  APIService.get(`subscriptions/refunds?page=${pageNo}&limit=${limit}&search=${search}`);

export const updateCancelSubscriptionSchedule = payload =>
  APIService.post('subscriptions/schedules/cancel', { ...payload });

export const updateAddSubscriptionSchedule = payload => APIService.post('subscriptions/schedules/add', { ...payload });

export const updateResumeSubscriptionSchedule = payload =>
  APIService.post('subscriptions/schedules/resume', { ...payload });

export const updateRemoveSubscriptionSchedule = payload =>
  APIService.post('subscriptions/schedules/remove', { ...payload });

export const getUserSubscriptions = ({ pageNo, limit, status }) =>
  APIService.get(`subscriptions?status=${status}&page=${pageNo}&limit=${limit}`);

export const getUserInvoices = ({ pageNo, limit }) =>
  APIService.get(`subscriptions/invoices?page=${pageNo}&limit=${limit}`);

export const getUserActivePlan = () => APIService.get('subscriptions/active');

export const getAvailablePlans = () => APIService.get('subscriptions/plans');

export const getUserSubscriptionSchedules = () => APIService.get('subscriptions/schedules');

export const getSubscriptions = ({ page, limit, status, isCanceled, search, plan, sort, order }) =>
  APIService.get(
    `subscriptions?page=${page}&limit=${limit}&status=${status}&isCanceled=${isCanceled}&search=${search}&plan=${plan}`,
  );

export const getSubscriptionsSchedules = () => APIService.get('subscriptions/schedules');

export const getSubscriptionInvoices = ({ pageNo, limit, search }) =>
  APIService.get(`subscriptions/invoices?page=${pageNo}&limit=${limit}&search=${search}`);

export const getUserIncompleteSubscription = () => APIService.get('subscriptions/incomplete');

export const getNextPaymentPhase = () => APIService.get('subscriptions/next-payment-phase');

export const cancelSubscription = () => APIService.post('subscriptions/cancel');

export const cancelSubscriptionPostReason = payload => APIService.post('subscriptions/cancel/reason', { ...payload });

export const getUserReferralCode = () => APIService.get('subscriptions/recommendation-code');

export const getUserUsedReferralCodes = ({ pageNo, limit }) =>
  APIService.get(`subscriptions/recommendation-code/used?page=${pageNo}&limit=${limit}`);

export const createUserReferralCode = () => APIService.post('subscriptions/recommendation-code');

export const sendReferralCode = payload =>
  APIService.post('subscriptions/recommendation-code/send-email', { ...payload });

export const updatePayment = payload => APIService.post('subscriptions/update-payment-details', { ...payload });

export const getAllRecommendations = ({ pageNo, limit, search }) =>
  APIService.get(`subscriptions/recommendation-codes?page=${pageNo}&limit=${limit}&search=${search}`);

export const getAllCancelReasons = ({ pageNo, limit, search }) =>
  APIService.get(`subscriptions/cancel-reasons?page=${pageNo}&limit=${limit}&search=${search}`);

export const changeStatusOfRecommendationCode = codeId =>
  APIService.post('subscriptions/recommendation-codes/change-status', { codeId });

export const addFreeDaysToUser = payload => APIService.post('subscriptions/add-free-days', { ...payload });

export const getUserEntitlements = () => APIService.get('subscriptions/entitlements');

export const getFeatures = () => APIService.get('subscriptions/features');

export const getSubscriptionHistory = ({ subscriptionId }) =>
  APIService.get(`subscriptions/history?subscriptionId=${subscriptionId}`);

export const getStudentSubscriptionHistory = userId => APIService.get(`subscriptions/user-history?userId=${userId}`);
