/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../services';

const initialState = {
  isServerAlive: true,
};

export const checkServerStatus = createAsyncThunk('healthcheck', async () => {
  return API.ServerService.checkServerHealth();
});

const serverSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setServerDown: state => {
      state.isServerAlive = false;
    },
  },
  extraReducers: {
    [checkServerStatus.rejected.toString()]: state => {
      state.isServerAlive = false;
    },
    [checkServerStatus.fulfilled.toString()]: (state, action) => {
      state.isServerAlive = action.payload.data;
    },
  },
});

export const { setServerDown } = serverSlice.actions;

export default serverSlice.reducer;
