/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  products: [],
  isLoading: false,
  product: null,
  productsByCourseId: [],
  packagesShopProducts: [],
  assignedShopProducts: [],
  isLoadingAssignedShopProducts: true,
  isLoadingPackagesShopProducts: true,
};

export const getAllProducts = createAsyncThunk('products/getUser', async () => {
  return API.ProductsService.getAllProducts();
});

export const addProduct = createAsyncThunk('products/addProduct', async payload => {
  return API.ProductsService.addProduct(payload);
});

export const getProductDetail = createAsyncThunk('products/getProductDetail', async productId => {
  return API.ProductsService.getProductDetail(productId);
});

export const addProductItem = createAsyncThunk('products/addProductItem', async payload => {
  return API.ProductsService.addProductItem(payload);
});

export const getProductDetailsAsStudent = createAsyncThunk('products/getProductDetailsAsStudent', async payload => {
  return API.ProductsService.getProductDetailsAsStudent(payload);
});

export const getPackagesShopProducts = createAsyncThunk('products/getPackagesShopProducts', async payload => {
  return API.ProductsService.getPackagesShopProducts(payload);
});

export const getAssignedProducts = createAsyncThunk('products/getAssignedProducts', async () => {
  return API.ProductsService.getAssignedProducts();
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllProducts.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllProducts.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getAllProducts.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.products = action.payload.data;
    },
    [addProduct.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać produktu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addProduct.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy produkt!`,
        description: 'Produkt został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [getProductDetail.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getProductDetail.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getProductDetail.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.product = action.payload.data;
    },
    [addProductItem.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać materiału!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addProductItem.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nowy materiał do produktu!`,
        description: 'Materiał został pomyślnie dodany do produktu.',
        placement: 'bottomRight',
      });
    },
    [getProductDetailsAsStudent.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getProductDetailsAsStudent.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getProductDetailsAsStudent.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.productsByCourseId = action.payload.data;
    },
    [getPackagesShopProducts.pending.toString()]: state => {
      state.isLoadingPackagesShopProducts = true;
    },
    [getAssignedProducts.rejected.toString()]: state => {
      state.isLoadingAssignedShopProducts = true;
    },
    [getPackagesShopProducts.fulfilled.toString()]: (state, action) => {
      state.packagesShopProducts = action.payload.data;
      state.isLoadingPackagesShopProducts = false;
    },
    [getAssignedProducts.fulfilled.toString()]: (state, action) => {
      state.assignedShopProducts = action.payload.data;
      state.isLoadingAssignedShopProducts = false;
    },
  },
});

export default productsSlice.reducer;
