/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: true,
  multimediadata: [],
  multimedia: [],
  multimediaTags: [],
};

export const getMultimedias = createAsyncThunk('multimedia/getMultimedias', async ({ filesIds }) => {
  if (filesIds !== undefined && filesIds.length !== 0) {
    return API.MultimediasService.getMultimedias({ filesIds });
  }
  return {};
});

export const getMultimediaDatas = createAsyncThunk(
  'multimedia/getMultimediaDatas',
  async ({ pageNo, limit, search }) => {
    return API.MultimediasService.getMultimediaDatas({ pageNo, limit, search });
  },
);

export const addMultimedia = createAsyncThunk('multimedia/addMultimedia', async payload => {
  return API.MultimediasService.addMultimedia(payload);
});

export const editMultimedia = createAsyncThunk('multimedia/editMultimedia', async ({ id, payload }) => {
  return API.MultimediasService.editMultimedia(id, payload);
});

export const getMultimediaTags = createAsyncThunk('multimedia/getMultimediaTags', async search => {
  return API.MultimediasService.getMultimediaTags(search);
});

export const getFile = createAsyncThunk('multimedia/getFile', async fileId => {
  return API.MultimediasService.getFile(fileId);
});

const multimediaSlice = createSlice({
  name: 'multimedia',
  initialState,
  reducers: {},
  extraReducers: {
    [getMultimediaDatas.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.multimediadata = action.payload.data;
    },
    [getMultimediaDatas.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({ message: 'Błąd', description: action.error.message });
    },
    [getMultimedias.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.multimedia = action.payload.data?.files;
    },
    [getMultimedias.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({ message: 'Error', description: action.error.message });
    },
    [addMultimedia.fulfilled.toString()]: (state, action) => {
      notification.success({ message: 'Success', description: 'Multimedia added successfully' });
    },
    [addMultimedia.rejected.toString()]: (state, action) => {
      notification.error({ message: 'Error', description: action.error.message });
    },
    [editMultimedia.fulfilled.toString()]: (state, action) => {
      notification.success({ message: 'Success', description: 'Multimedia edited successfully' });
    },
    [editMultimedia.rejected.toString()]: (state, action) => {
      notification.error({ message: 'Error', description: action.error.message });
    },
    [getMultimediaTags.fulfilled.toString()]: (state, action) => {
      state.multimediaTags = action.payload.data;
    },
    [getMultimediaTags.rejected.toString()]: (state, action) => {
      notification.error({ message: 'Błąd', description: action.error.message });
    },
  },
});

export const {} = multimediaSlice.actions;

export default multimediaSlice.reducer;
