import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row id="main-row-register">
        <Col xxl={9} xl={9} lg={9} md={0} xs={0}>
          <Aside>
            <div className="auth-side-content" id="aside">
              {/* <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <br />
              <br />
              <br />
              <Content>
                <Heading as="h1" className="auth-header">
                  Przygotowanie Do Matury z Informatyki
                </Heading>
                <img
                  className="auth-content-figure"
                  src={require('../../../static/img/auth/Illustration.png')}
                  alt=""
                  style={{
                    width: '90%',
                  }}
                />
              </Content>
              <br />
              <br /> */}
            </div>
          </Aside>
        </Col>

        <Col xxl={15} xl={15} lg={15} md={24} xs={24} className="forms-content">
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
