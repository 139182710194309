import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Quiz = lazy(() => import('../container/quiz/index'));

const QuizRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={Quiz} />
    </Switch>
  );
};

export default QuizRoutes;
