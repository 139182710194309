import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { AvatarStyledEmpty } from './style';

const NoImageAvatar = ({ wrapperSize, iconSize }) => {
  return (
    <AvatarStyledEmpty className="avatar-border-dropdown" size={wrapperSize ?? 26}>
      <FeatherIcon icon="user" size={iconSize ?? 12} strokeWidth={3} />
    </AvatarStyledEmpty>
  );
};

export { NoImageAvatar };
