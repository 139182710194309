// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const addReward = payload => APIService.post('rewards', { ...payload });

export const editReward = payload => APIService.put('rewards', { ...payload });

export const getAllRewards = () => APIService.get('rewards');

export const assignPointsToCourse = payload => APIService.post('points/courses', { ...payload });

export const editPointsToCourse = payload => APIService.put('points/courses', { ...payload });

export const getExcercisesPoints = payload => {
  return APIService.get(`points/courses/${payload.courseId}/subjects/${payload.subjectId}`);
};

export const getUsersCourseExcercisePoints = payload => {
  return APIService.get(`points/courses/${payload.courseId}/subjects/${payload.subjectId}/assigned`);
};

export const approveUserExcercise = payload => APIService.post(`points/courses/materials/points`, { ...payload });

export const getUserCourseExcercisePoints = payload =>
  APIService.get(`points/courses/${payload.courseId}/subjects/${payload.subjectId}/users/${payload.userId}`);

export const getStudentsPoints = payload => APIService.get(`points/courses/${payload.courseId}`);

export const getStudentsPointsAsAdmin = payload =>
  APIService.get(`points/courses/${payload.courseId}/users/${payload.userId}`);

export const getStudentsRewardsAsAdmin = payload =>
  APIService.get(`rewards/courses/${payload.courseId}/users/${payload.userId}/assigned`);

export const assignRewardAsStudet = payload => APIService.post('rewards', { ...payload });

export const adminGetAllAssignedRewards = () => APIService.get('rewards/assigned');

export const adminChangeRequestedRewardsStatus = payload => APIService.put('rewards/assigned/status', { ...payload });

export const getStudentsReceivedRewards = payload => APIService.get(`rewards/courses/${payload.courseId}/assigned`);
