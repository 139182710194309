const actions = {
  SET_COLLAPSED: 'SET_COLLAPSED',

  setCollapsed: collapsed => {
    return {
      type: actions.SET_COLLAPSED,
      collapsed,
    };
  },
};

export default actions;
