import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { UserTableStyleWrapper } from '../style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { Avatar } from '../../../components/avatar/Avatar';
import Heading from '../../../components/heading/heading';

import { Cards } from '../../../components/cards/frame/cards-frame';
import { getActiveStudentsWithoutSubscription } from '../../../ducks/students';

const ActiveSudentsWithoutSubscription = () => {
  const dispatch = useDispatch();

  const { students, isLoading } = useSelector(state => {
    return {
      students: state.students.activeStudentsWithoutSubscription,
      isLoading: state.students.isLoading,
    };
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });

  const studentsTableColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Uzytkownik',
      dataIndex: 'user',
      key: 'user',
      render: student => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={student._id} firstName={student.firstName} lastName={student.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${student.firstName} ${student.lastName}`}
              </Heading>
              <span className="user-designation">{student.email}</span>
              <span className="user-designation">{student.role.toUpperCase()}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Wygasniecie',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: expiredAt => {
        return <span>{new Date(expiredAt).toLocaleString()}</span>;
      },
    },
  ];

  const studentsTableData = students.data?.map((student, index) => {
    return {
      id: student._id,
      index: index + 1,
      user: student.user,
      expiredAt: student.expiredAt,
    };
  });

  useEffect(() => {
    dispatch(
      getActiveStudentsWithoutSubscription({
        page: pagination.current,
        limit: pagination.pageSize,
      }),
    );
  }, [dispatch, pagination]);

  return (
    <>
      <PageHeader ghost title="Studenci z aktywnymi kontami bez subskrypcji" />
      <Main>
        <Cards headless>
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                dataSource={studentsTableData}
                isLoading={isLoading}
                columns={studentsTableColumns}
                pagination={{
                  defaultPageSize: pagination.defaultPageSize,
                  total: students.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} studentów`,
                }}
                rowKey={record => record.id}
                onChange={pagin => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default ActiveSudentsWithoutSubscription;
