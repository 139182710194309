/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  userTokens: [],
  isLoading: false,
};

export const getUserTokens = createAsyncThunk('userTokens/getUserTokens', async () => {
  return API.UserTokensService.getUserTokens();
});

export const removeUserToken = createAsyncThunk('userTokens/removeUserToken', async payload => {
  return API.UserTokensService.removeUserToken(payload);
});

const accessCodesSlice = createSlice({
  name: 'userTokens',
  initialState,
  extraReducers: {
    [getUserTokens.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getUserTokens.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać tokenów logowania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getUserTokens.fulfilled.toString()]: (state, action) => {
      state.userTokens = action.payload.data;
      state.isLoading = false;
    },
    [removeUserToken.pending.toString()]: state => {
      state.isLoading = true;
    },
    [removeUserToken.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się usunąć tokenu logowania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeUserToken.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Usunięto token logowania!',
        placement: 'bottomRight',
      });
    },
  },
});

export const { addSubmessageToPost } = accessCodesSlice.actions;

export default accessCodesSlice.reducer;
