import { APIService, APIServiceAllRoles, APIServiceHealthCheck, UnauthorizedService } from './ApiService';

export const getUser = () => APIServiceAllRoles.get('users/profile');

export const getUserById = userId => APIService.get(`users/${userId}`);

export const getUserByEmail = email => APIService.get(`users/email/${email}`);

export const changeUserName = payload => APIServiceAllRoles.put('users/details', { ...payload });

export const changePassword = payload => APIServiceAllRoles.put('users/password', { ...payload });

export const healthCheck = () => APIServiceHealthCheck.get('users/healthcheck');

export const notify = (message, email) => UnauthorizedService.post('users/notify', { message, email });
