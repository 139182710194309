import React from 'react';
import { Modal } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons/buttons';
import actions from '../../../redux/announcementModal/actions';

const AnnouncementModal = ({ user, footer }) => {
  const dispatch = useDispatch();
  const kmiOrKmm = process.env.REACT_APP_THEME === 'KMI';

  const { announcementModalVisible } = useSelector(state => {
    return {
      announcementModalVisible: state.announcementModal.announcementModalVisible,
    };
  });

  const handleTesterModalVisibility = () => {
    dispatch(actions.setAnnouncementModalVisible(false));
  };

  const onClickCancel = () => {
    handleTesterModalVisibility();
  };

  const onClickAccept = () => {
    // eslint-disable-next-line no-useless-return
    return;
  };

  const modalFooter = footer
    ? [
        <>
          <Button
            key="add"
            type="primary"
            onClick={onClickCancel}
            className="dont-cancel-subscription-button announcement"
          >
            Anuluj
          </Button>
          <Button
            key="add"
            type="primary"
            onClick={onClickAccept}
            className="confirm-cancel-subscription-button right announcement"
            loading={false}
          >
            Potwierdzam
          </Button>
        </>,
      ]
    : [];
  return user.isActive && announcementModalVisible && kmiOrKmm ? (
    <Modal
      title=""
      visible={announcementModalVisible}
      onCancel={onClickCancel}
      closeIcon={
        <FeatherIcon
          size={60}
          icon="x-circle"
          className="close-modal-x-icon"
          color="#AFB4CD"
          fill="#FFFFFF"
          strokeWidth={1}
        />
      }
      footer={modalFooter}
      className="cancel-subscription-modal cancel announcement"
    >
      <div className="announcement-modal-description" style={{ fontSize: 17, fontWeight: 700 }}>
        Zostań testerem kursu do matury z <span className="blue-text">matematyki</span>!
      </div>

      <div className="announcement-modal-description">
        Z radością informujemy, że nasz nowy Kurs Matura Matematyka{' '}
        <a href="https://kursmaturamatematyka.pl" className="blue-text link" style={{ fontWeight: 'bold' }}>
          kursmaturamatematyka.pl
        </a>{' '}
        prowadzony przez drugi zespół jest już dostępny!
      </div>
      <div className="announcement-modal-description">
        Jako że mamy wielu wyjątkowych i zaangażowanych uczniów, którzy nie raz pokazali swoje umiejętności w ocenie
        naszych materiałów, chcemy zaprosić Cię do współpracy. Kto lepiej jak nie nasi uczniowie, mogą wskazać nam, co
        jeszcze możemy ulepszyć?
      </div>
      <div className="announcement-modal-description">
        Jeśli masz zakupione <span className="black-text tester-modal">wszystkie moduły</span> kursu do matury z
        informatyki, możesz
        <span className="black-text tester-modal">
          {' '}
          bezpłatnie skorzystać z całego kursu przygotowującego do matury z matematyki
        </span>{' '}
        - na poziomie podstawowym i rozszerzonym.
      </div>
      <div className="announcement-modal-description">
        W celu uzyskania dostępu do kursu, wystarczy utworzyć zgłoszenie z tytułem “Dostęp do Kurs Matura Matematyka”. W
        odpowiedzi prześlemy dalsze instrukcje dotyczące dostępu i testowania kursu.
      </div>
      <div className="announcement-modal-description">
        Testerów dodajemy tylko do dnia 15.10.2024, więc nie zwlekaj! Oczywiście testerom zapewniamy pełny dostęp do
        materiałów z matematyki, analogicznie jak w przypadku kursu z informatyki, czyli do końca lipca po maturze.
      </div>
      <div className="announcement-modal-description">Dziękujemy za Twoje wsparcie i zaangażowanie!</div>
    </Modal>
  ) : (
    <></>
  );
};

export default AnnouncementModal;
