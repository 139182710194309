import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { CourseStore } from '../ducks/store';
import { logOut, logOutToken } from '../ducks/auth';
import { setServerDown } from '../ducks/server';

export const APIService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const APIServiceAllRoles = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

APIService.interceptors.request.use(async function(config) {
  const { accessToken } = CourseStore.getState().auth;
  const { user } = CourseStore.getState().user;
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  const role = user.role === 'SENIOR-TEACHER' ? 'teacher' : user.role.toLowerCase();
  config.headers = headers;
  config.baseURL = `${process.env.REACT_APP_API_URL}${role}/`;
  return config;
});

APIServiceAllRoles.interceptors.request.use(async function(config) {
  const { accessToken } = CourseStore.getState().auth;
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  config.headers = headers;
  return config;
});

export const APIServiceLogout = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

APIServiceLogout.interceptors.request.use(async function(config) {
  const { accessToken } = CourseStore.getState().auth;
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  config.headers = headers;
  return config;
});

APIServiceLogout.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.toString() === 'Error: Network Error') {
      CourseStore.dispatch(setServerDown());
    } else if (error.response && error.response.status === 401) {
      CourseStore.dispatch(logOut());
    } else if (error.response && error.response.status === 500) {
      CourseStore.dispatch(setServerDown());
    }
    return Promise.reject(error.response.data);
  },
);

APIServiceAllRoles.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.toString() === 'Error: Network Error') {
      CourseStore.dispatch(setServerDown());
    } else if (error.response && error.response.status === 401) {
      CourseStore.dispatch(logOutToken());
    } else if (error.response && error.response.status === 500) {
      CourseStore.dispatch(setServerDown());
    }
    return Promise.reject(error.response.data);
  },
);

APIService.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.toString() === 'Error: Network Error') {
      CourseStore.dispatch(setServerDown());
    } else if (error.response && error.response.status === 401) {
      CourseStore.dispatch(logOutToken());
    } else if (error.response && error.response.status === 500) {
      CourseStore.dispatch(setServerDown());
    }
    return Promise.reject(error.response.data);
  },
);

export const UnauthorizedService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

UnauthorizedService.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.toString() === 'Error: Network Error') {
      CourseStore.dispatch(setServerDown());
    } else if (error.response && error.response.status === 401) {
      CourseStore.dispatch(logOut());
    } else if (error.response && error.response.status === 500) {
      CourseStore.dispatch(setServerDown());
    }
    return Promise.reject(error.response.data);
  },
);

export const APIServiceHealthCheck = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

APIServiceHealthCheck.interceptors.request.use(async function(config) {
  const { accessToken } = CourseStore.getState().auth;
  const headers = {
    'content-type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  config.headers = headers;
  return config;
});

APIServiceHealthCheck.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  error => {
    return Promise.resolve(error.response.data);
  },
);
