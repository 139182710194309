/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const getSingleQuestion = () => APIService.get('questions');

export const getQuestionsWithStats = () => APIService.get('questions');

export const addUserQuestionAnswer = payload => APIService.post('questions/answers', { ...payload });

export const getQuizStats = () => APIService.get('questions/stats');

export const editQuestion = payload => APIService.put('questions', { ...payload });

export const addQuestion = payload => APIService.post('questions', { ...payload });
