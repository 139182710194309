import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { Row, Col, Radio, Input, DatePicker } from 'antd';
import { render } from '@testing-library/react';
import { Avatar } from '../../../components/avatar/Avatar';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { UserTableStyleWrapper } from '../../students/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { getLastTeachersComments } from '../../../ducks/materials';
import Heading from '../../../components/heading/heading';
import { TopToolBox } from '../../ecommerce/Style';
import { getTeachersList } from '../../../ducks/crm';

const RecentTeachersComments = () => {
  const dispatch = useDispatch();

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });
  const [search, setSearch] = useState('');
  const [teacherId, setTeacherId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const history = useHistory();
  const { lastTeahersComments, isLoadingLastTeachersComments, teachers } = useSelector(state => {
    return {
      lastTeahersComments: state.materials.lastTeahersComments,
      isLoadingLastTeachersComments: state.materials.isLoadingLastTeachersComments,
      teachers: state.crm.teachers,
    };
  });

  useEffect(() => {
    dispatch(
      getLastTeachersComments({
        page: pagination.current,
        limit: pagination.pageSize,
        search,
        teacherId,
        startDate,
        endDate,
      }),
    );
  }, [dispatch, pagination, search, teacherId, startDate, endDate]);

  useEffect(() => {
    dispatch(getTeachersList());
  }, [dispatch]);

  const columns = [
    {
      title: 'Nauczyciel',
      dataIndex: 'teacher',
      key: 'teacher',
      render: teacher => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={teacher._id} firstName={teacher.firstName} lastName={teacher.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${teacher.firstName} ${teacher.lastName}`}
              </Heading>
              <span className="user-designation">{teacher.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Komentarz',
      dataIndex: 'text',
      key: 'text',
      render: text => {
        // if text is longer than 100 characters, show only first 100 characters
        return <span>{text.length > 100 ? `${text.substring(0, 100)}...` : text}</span>;
      },
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>;
      },
    },
  ];

  const commentsTableData = lastTeahersComments.data?.map(comment => {
    return {
      key: comment._id,
      teacher: comment.user,
      text: comment.text,
      assignedSubject: comment.assignedSubject,
      section: comment.section,
      subject: comment.subject,
      createdAt: comment.createdAt,
    };
  });

  const handleSearch = useCallback(
    value => {
      setSearch(value);
      setPagination({
        ...pagination,
        current: 1,
      });
    },
    [pagination],
  );

  const onClickRow = record => {
    history.push(
      `/students/${record.assignedSubject.userId}/courses/${record.assignedSubject.courseId}/materials/${record.section.materialId}/sections/${record.section._id}/exercise/${record.subject._id}`,
    );
  };

  return (
    <>
      <PageHeader ghost title="Ostatnie komentarze nauczycieli" />
      <Main>
        <Cards headless>
          <Input.Search
            placeholder="Wyszukaj treści komentarza..."
            enterButton
            onSearch={handleSearch}
            style={{ maxWidth: 400, marginBottom: 20 }}
          />
          <TopToolBox>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Radio.Group
                  onChange={e => {
                    setTeacherId(e.target.value);
                    setPagination({
                      ...pagination,
                      current: 1,
                    });
                  }}
                  value={teacherId}
                >
                  <Radio.Button value="">Wszyscy</Radio.Button>
                  {teachers.map(teacher => {
                    return (
                      <Radio.Button key={teacher._id} value={teacher._id}>
                        {`${teacher.firstName} ${teacher.lastName}`}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <DatePicker
                  placeholder="Data od"
                  value={startDate}
                  onChange={date => {
                    // set time to 00:00:00
                    date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                    setStartDate(date);
                    setPagination({
                      ...pagination,
                      current: 1,
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <DatePicker
                  placeholder="Data do"
                  value={endDate}
                  onChange={date => {
                    date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
                    setEndDate(date);
                    setPagination({
                      ...pagination,
                      current: 1,
                    });
                  }}
                />
              </Col>
            </Row>
          </TopToolBox>

          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                onRow={record => {
                  return {
                    onClick: () => {
                      onClickRow(record);
                    },
                  };
                }}
                expandable={{
                  expandedRowRender: record => {
                    // make it word-wrap
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{record.text}</p>;
                  },
                  rowExpandable: record => record.text.length > 100,
                }}
                loading={isLoadingLastTeachersComments}
                dataSource={commentsTableData}
                columns={columns}
                pagination={{
                  defaultPageSize: 10,
                  total: lastTeahersComments.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} studentów`,
                }}
                onChange={(pagin, filters, sorter) => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default RecentTeachersComments;


//http://localhost:3001/students/6706706eba51d4582bc19bee/courses/6706706dba51d4582bc198df/materials/6706706dba51d4582bc198e9/sections/6706706dba51d4582bc198ea/exercise/6706706dba51d4582bc198f4
//http://localhost:3001/student/6706706eba51d4582bc19d11/courses/6706706dba51d4582bc198df/materials/6706706dba51d4582bc198e9/sections/6706706dba51d4582bc198ea/exercise/6706706dba51d4582bc198f4