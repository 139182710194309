// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getMaterialVideos = ({ page, limit, search }) =>
  APIService.get(`materials/videos?page=${page}&limit=${limit}&search=${search}`);

export const addMaterialVideo = payload => APIService.post('materials/videos', payload);

export const editMaterialVideo = payload => APIService.put('materials/videos', payload);

export const getMaterialVideosTags = search => APIService.get(`materials/videos/tags?search=${search}`);
