import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const MaterialVideos = lazy(() => import('../container/materialVideos'));

const MaterialVideosRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={MaterialVideos} />
    </Switch>
  );
};

export default MaterialVideosRoutes;
