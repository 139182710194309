const actions = {
  SET_ANNOUNCEMENT_MODAL_VISIBLE: 'SET_ANNOUNCEMENT_MODAL_VISIBLE',

  setAnnouncementModalVisible: visible => {
    return {
      type: actions.SET_ANNOUNCEMENT_MODAL_VISIBLE,
      visible,
    };
  },
};

export default actions;
