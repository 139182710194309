/* eslint-disable import/no-cycle */
import { APIService } from './ApiService';

export const getAllRatings = (page, limit, search, isWithComment, selectRating) =>
  APIService.get(
    `ratings/lectures?page=${page}&limit=${limit}&search=${search}&isWithComment=${isWithComment}&selectRating=${selectRating}`,
  );

export const postLectureRate = payload => APIService.post(`ratings/lectures`, { ...payload });

export const getLectureInformations = subjectId => APIService.get(`ratings/lectures/${subjectId}`);
