/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  user: null,
  isLoading: false,
};

export const getUser = createAsyncThunk('user/getUser', async () => {
  return API.UserService.getUser();
});

export const changeUserName = createAsyncThunk('user/changeUserName', async payload => {
  return API.UserService.changeUserName(payload);
});

export const changePassword = createAsyncThunk('user/changePassword', async payload => {
  return API.UserService.changePassword(payload);
});

export const healthCheck = async () => {
  return API.UserService.healthCheck();
};

export const notify = async (message, email) => {
  return API.UserService.notify(message, email);
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: state => {
      state.user = null;
    },
  },
  extraReducers: {
    [getUser.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getUser.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getUser.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload.data;
    },
    [changeUserName.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zmienić danych!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [changeUserName.fulfilled.toString()]: () => {
      notification.success({
        message: `Zmieniono dane!`,
        description: 'Twoje dane zostały poprawnie zmienione.',
        placement: 'bottomRight',
      });
    },
    [changePassword.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zmienić hasła!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [changePassword.fulfilled.toString()]: () => {
      notification.success({
        message: `Zmieniono hasło!`,
        description: 'Twoje hasło zostało poprawnie zmienione.',
        placement: 'bottomRight',
      });
    },
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
