/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: false,
  ratings: [],
  isSendingLectureRateLoading: false,
  isLectureAlreadyRated: false,
  lectureInformations: {},
};

export const getAllRatings = createAsyncThunk(
  'ratings/getAllRatings',
  async ({ page, limit, search, isWithComment, selectRating }) => {
    return API.RatingsService.getAllRatings(page, limit, search, isWithComment, selectRating);
  },
);

export const postLectureRate = createAsyncThunk('ratings/postLectureRate', async payload => {
  return API.RatingsService.postLectureRate(payload);
});

export const getLectureInformations = createAsyncThunk('ratings/getLectureInformations', async subjectId => {
  return API.RatingsService.getLectureInformations(subjectId);
});

const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllRatings.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllRatings.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać ostrzeżeń',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllRatings.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.ratings = action.payload.data;
    },
    [postLectureRate.pending.toString()]: state => {
      state.isSendingLectureRateLoading = true;
    },
    [postLectureRate.fulfilled.toString()]: state => {
      state.isSendingLectureRateLoading = false;
    },
    [postLectureRate.rejected.toString()]: (state, action) => {
      state.isSendingLectureRateLoading = false;
      notification.error({
        message: 'Nie udało się przesłać opinii!',
        description: `${
          action.error.message === 'Rating already exists'
            ? 'Opinia została już wystawiona'
            : action.error.message === 'Rating must be between 1 and 5'
            ? 'Opinia musi być w skali od 1 do 5'
            : action.error.message === 'Assigned lecture not found'
            ? 'Oceniany wykład nie został znaleziony'
            : action.error.message === 'Lecture not found'
            ? 'Wykład nie został znaleziony'
            : ''
        }`,
        placement: 'bottomRight',
      });
    },
    [getLectureInformations.pending.toString()]: state => {},
    [getLectureInformations.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać informacji o wykładzie',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getLectureInformations.fulfilled.toString()]: (state, action) => {
      state.lectureInformations = action.payload.data;
    },
  },
});

export default ratingsSlice.reducer;
