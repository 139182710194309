/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  question: {},
  stats: {
    userAnswers: 0,
    correctAnswersCount: 0,
    inCorrectAnswersCount: 0,
    allQuestions: 0,
  },
  isLoading: true,
  isStatsLoading: true,
};

export const getSingleQuestion = createAsyncThunk('coupons/getSingleQuestion', async () => {
  return API.QuizService.getSingleQuestion();
});

export const getQuizStats = createAsyncThunk('questions/getQuizStats', async () => {
  return API.QuizService.getQuizStats();
});

export const addUserQuestionAnswer = createAsyncThunk('coupons/addUserQuestionAnswer', async payload => {
  return API.QuizService.addUserQuestionAnswer(payload);
});

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: {
    [getSingleQuestion.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSingleQuestion.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać pytań!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSingleQuestion.fulfilled.toString()]: (state, action) => {
      state.question = action.payload.data;
      state.isLoading = false;
    },
    [getQuizStats.pending.toString()]: state => {
      state.isStatsLoading = true;
    },
    [getQuizStats.rejected.toString()]: (state, action) => {
      state.isStatsLoading = false;
    },
    [getQuizStats.fulfilled.toString()]: (state, action) => {
      state.isStatsLoading = false;
      state.stats = action.payload.data;
    },
    [addUserQuestionAnswer.pending.toString()]: state => {
      state.isLoading = true;
    },
    [addUserQuestionAnswer.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się dodać odpowiedzi!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addUserQuestionAnswer.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.question = action.payload.data[0];
    },
  },
});

export const {} = quizSlice.actions;

export default quizSlice.reducer;
