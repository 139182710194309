import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Ratings = lazy(() => import('../container/ratings'));

const RatingsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Ratings} />
    </Switch>
  );
};

export default RatingsRoutes;
