import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const RecentVerifiedMaterials = lazy(() => import('../container/materials/overview/RecentVerifiedMaterials'));
const SingleSubject = lazy(() => import('../container/materials/overview/SingleSubject'));

const RecentVerifiedMaterialsRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={RecentVerifiedMaterials} />
      <Route
        path={`${path}/:studentId/courses/:courseId/materials/:materialId/sections/:sectionId/:subjectType/:subjectId`}
        component={SingleSubject}
      />
    </Switch>
  );
};

export default RecentVerifiedMaterialsRoutes;
