import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkMode: false,
  rtl: false,
  topMenu: false,
};

const themeLayoutSlice = createSlice({
  name: 'themeLayout',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default themeLayoutSlice.reducer;
