import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'antd/lib/table';
import 'antd/lib/table/style/css';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, TableWrapper } from '../../styled';
import { Avatar } from '../../../components/avatar/Avatar';
import Heading from '../../../components/heading/heading';
import { UserTableStyleWrapper } from '../../students/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { changeStatusOfRecommendationCode, getAllRecommendations } from '../../../ducks/subscriptions';
import { Badge, Button, Input, Statistic, Tag } from 'antd';


const Recommendations = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');

  const { recommendations, isLoading } = useSelector(state => {
    return {
      recommendations: state.subscriptions.recommendations,
      isLoading: state.students.isLoading,
    };
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    total: 0,
  });

  const changeStatus = codeId =>
    useCallback(() => {
      dispatch(changeStatusOfRecommendationCode(codeId));
      //wait for the action to finish
      setTimeout(() => {
        dispatch(
          getAllRecommendations({
            pageNo: pagination.current,
            limit: pagination.pageSize,
            search: search,
          }),
        );
      }, 1000);
    }, [dispatch]);

  const recommendationsTableColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Uzytkownik',
      dataIndex: 'user',
      key: 'user',
      render: student => {
        return (
          <div className="user-info">
            <figure>
              <Avatar userId={student._id} firstName={student.firstName} lastName={student.lastName} />
            </figure>
            <figcaption>
              <Heading className="user-name" as="h6">
                {`${student.firstName} ${student.lastName}`}
              </Heading>
              <span className="user-designation">{student.email}</span>
            </figcaption>
          </div>
        );
      },
    },
    {
      title: 'Kod',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Liczba uzyc',
      dataIndex: 'numberOfUses',
      key: 'numberOfUses',
      render: numberOfUses => {
        return <Statistic value={numberOfUses}/>;
      },
    },
    {
      title: 'Czy aktywny',
      dataIndex: 'isActive',
      key: 'isActive',
      render: isActive => {
        return <Tag color={isActive ? 'darkgreen' : 'darkred'}>{isActive ? 'Tak' : 'Nie'}</Tag>;
      },
    },
    {
      title: 'Utworzono',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{new Date(createdAt).toLocaleString()}</span>;
      },
    },
    {
      title: 'Akcje',
      dataIndex: 'id',
      key: 'id',
      render: id => {
        return (
          <Button type="primary" size="small" onClick={changeStatus(id)}>
            Zmień status
          </Button>
        );
      },
    },
  ];

  const handleSearch = value => {
    setSearch(value);
    dispatch(
      getAllRecommendations({
        pageNo: pagination.current,
        limit: pagination.pageSize,
        search: value,
      }),
    );
  };

  const recommendationsTableData = recommendations.data?.map((recommendation, index) => {
    return {
      id: recommendation._id,
      index: index + 1,
      user: recommendation.user,
      isActive: recommendation.isActive,
      code: recommendation.code,
      numberOfUses: recommendation.numberOfUses,
      usedCodes: recommendation.usedCodes,
      createdAt: recommendation.createdAt,
    };
  });

  useEffect(() => {
    dispatch(
      getAllRecommendations({
        pageNo: pagination.current,
        limit: pagination.pageSize,
        search: search,
      }),
    );
  }, [dispatch, pagination]);

  return (
    <>
      <PageHeader ghost title="Kody rekomendacji" />
      <Main>
        <Cards headless>
          <Input.Search
            placeholder="Wyszukaj po imieniu, nazwisku lub emailu użytkownika"
            enterButton
            onSearch={handleSearch}
            style={{ maxWidth: 400, marginBottom: 20 }}
          />
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                dataSource={recommendationsTableData}
                isLoading={isLoading}
                columns={recommendationsTableColumns}
                pagination={{
                  defaultPageSize: pagination.defaultPageSize,
                  total: recommendations?.total,
                  showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} kodów rekomendacji`,
                }}
                rowKey={record => record.id}
                expandable={{
                  expandedRowRender: record => {
                    const usedCodesColumns = [
                      {
                        title: 'Uzytkownik',
                        dataIndex: 'user',
                        key: 'user',
                        render: student => {
                          return (
                            <div className="user-info">
                              <figure>
                                <Avatar
                                  userId={student._id}
                                  firstName={student.firstName}
                                  lastName={student.lastName}
                                />
                              </figure>
                              <figcaption>
                                <Heading className="user-name" as="h6">
                                  {`${student.firstName} ${student.lastName}`}
                                </Heading>
                                <span className="user-designation">{student.email}</span>
                              </figcaption>
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Data uzycia',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: usedAt => {
                          return <span>{new Date(usedAt).toLocaleString()}</span>;
                        },
                      },
                    ];
                    const usedCodesData = record.usedCodes.map((usedCode, index) => {
                      return {
                        key: index,
                        user: usedCode.user,
                        createdAt: usedCode.createdAt,
                      };
                    });
                    return (
                      <Table
                        columns={usedCodesColumns}
                        dataSource={usedCodesData}
                        pagination={false}
                        rowKey={record => record.key}
                      />
                    );
                  },
                  rowExpandable: record => record.numberOfUses > 0,
                }}
                onChange={pagin => {
                  setPagination({
                    ...pagination,
                    current: pagin.current,
                    pageSize: pagin.pageSize,
                  });
                }}
              />
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default Recommendations;
