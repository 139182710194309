import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const QuestionDatabase = lazy(() => import('../container/questionDatabase/QuestionDatabase'));
const QuestionsTable = lazy(() => import('../container/questionDatabase/QuestionsTable'));
const VerifyQuestions = lazy(() => import('../container/questionDatabase/VerifyQuestions'));

const QuestionDatabaseRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={QuestionDatabase} />.
      <Route path={`${path}/questions`} component={QuestionsTable} />
      <Route path={`${path}/verify-questions`} component={VerifyQuestions} />
    </Switch>
  );
};

export default QuestionDatabaseRoutes;
