import Styled from 'styled-components';

const MenuFontProvider = Styled.div`
    font-family: 'Red Hat Display', sans-serif!important;
    display: flex;
    max-height: 48px;
    @media only screen and (max-width: 400px){
        margin-left: -10px !important;
            }
            @media only screen and (max-width: 375px){
                //margin-left: -15px !important;
            }
            @media only screen and (max-width: 325px){
                margin-left: -5px !important;
            }
    #questions-base-hover {
&:hover {
cursor: pointer !important;
}
    }
    #rewards-base-hover {
&:hover {
cursor: pointer !important;
}
    }
    .selected-menu-item 
    {
        background-color: transparent;
             
              font-weight: 700;
              .ant-menu-title-content {
        color: ${({ theme }) => theme.primaryButtonsColor} !important;
        opacity: 0.9;
        display: flex;
        flex-direction: column;
        line-height: 5px;
        font-size: 8px !important;
        margin-top: -4px;
        @media only screen and (max-width: 335px){
                 font-size: 10px;
            }
    }
            }
    .normal-menu-item 
    {
        background-color: transparent;
              font-weight: 700;
              .ant-menu-title-content {
        color: #868EAE !important;
        opacity: 0.9;
        display: flex;
        flex-direction: column;
        line-height: 5px;
        font-size: 8px !important;
        margin-top: -4px;
        @media only screen and (max-width: 335px){
                 font-size: 10px;
            }
    }
            }
    .ant-menu-item.ant-menu-item-active.ant-menu-item-disabled.normal-menu-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        max-width: 80px;
        width: 80px;
        @media only screen and (max-width: 375px){
                 max-width: 90px; width: 90px;
            }
            @media only screen and (max-width: 335px){
                 max-width:78px; width:78px;
            }
    }
    .ant-menu-item.ant-menu-item-active.ant-menu-item-disabled.selected-menu-item {
        border-radius: 0px !important;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        max-width: 80px;
        width: 80px;
        @media only screen and (max-width: 375px){
                        width: 90px; width: 90px;
            }
            @media only screen and (max-width: 335px){
                 max-width:78px; width:78px;
            }
    }
    /* .first-item-top-list { margin-top: 0px; }
    .ant-menu-vertical.ant-menu-item { margin-top: 0px !important; } */
    .ant-menu-item .ant-menu-item-icon {
        max-width: 12px;
        margin-top: -4px;
    }
    .ant-menu-item .ant-menu-item-icon + span  { margin-left: 0px; }
    .selected-menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 80px !important;
    }
    .normal-menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 80px !important;
    }
`;
export { MenuFontProvider };
