import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const Register = lazy(() => import('../container/register'));
const PaymentSuccess = lazy(() => import('../container/payments/PaymentSuccess'));
const PaymentFailure = lazy(() => import('../container/payments/PaymentFailure'));
const RegisterSuccess = lazy(() => import('../container/register/overview/RegisterSuccess'));

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/cart/checkout/:orderId/successed" component={PaymentSuccess} />
        <Route exact path="/cart/checkout/:orderId/failed" component={PaymentFailure} />
        <Route exact path="/registerSuccess" component={RegisterSuccess} />
        <Route path="*" component={Login} />
      </Switch>
    </Suspense>
  );
};

export default AuthLayout(FrontendRoutes);
