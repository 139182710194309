/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  accessToken: null,
  isLoading: false,
};

export const login = createAsyncThunk('auth/login', async payload => {
  return API.AuthService.login(payload);
});

export const logOutToken = createAsyncThunk('auth/logOut', async () => {
  return API.AuthService.logOutToken();
});

export const register = createAsyncThunk('auth/register', async payload => {
  if (payload.firstName.length < 3 || payload.lastName.length < 3) {
    throw new Error('Imię lub nazwisko musi mieć co najmniej 3 znaki.');
  }
  return API.AuthService.register(payload);
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: state => {
      state.accessToken = null;
    },
  },
  extraReducers: {
    [login.pending.toString()]: state => {
      state.isLoading = true;
    },
    [login.rejected.toString()]: (state, action) => {
      state.isLoading = true;
      notification.error({
        message: 'Nie udało się zalogować!',
        description: action.error.message.includes('on another device')
          ? 'Użytkownik zalogowany na innym urządzeniu. Wyloguj się z innego urządzenia, spróbuj zalogować się ponownie lub poczekaj do północy na restart'
          : action.error.message === 'Wrong username or password'
          ? 'Niepoprawny login lub hasło'
          : action.error.message,
        placement: 'bottomRight',
      });
    },
    [login.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.accessToken = action.payload.data.access_token;
      notification.success({
        message: `Zalogowano!`,
        placement: 'bottomRight',
      });
    },
    [logOutToken.pending.toString()]: state => {
      state.isLoading = true;
    },
    [logOutToken.fulfilled.toString()]: state => {
      state.isLoading = false;
      state.accessToken = null;
      // notification.success({
      //   message: `Wylogowano użytkownika!`,
      //   description: 'Zostałeś pomyślnie wylogowany.',
      //   placement: 'bottomRight',
      // });
    },
    [register.pending.toString()]: state => {
      state.isLoading = true;
    },
    [register.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      switch (action.error.message) {
        case 'User already exist in database':
          notification.error({
            message: 'Użytkownik już istnieje!',
            description: 'Użytkownik o podanym adresie e-mail już istnieje w bazie danych.',
            placement: 'bottomRight',
          });
          break;
        default:
          notification.error({
            message: 'Nie udało się zarejestrować!',
            description: action.error.message,
            placement: 'bottomRight',
          });
          break;
      }
    },
    [register.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: `Utworzono konto!`,
        description: 'Konto zostało utworzone poprawnie.',
        placement: 'bottomRight',
      });
    },
  },
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;
