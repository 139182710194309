/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  orders: [],
  monthlyOrdersStats: [],
  monthlyPackagesStats: [],
  yearlyPackagesStats: [],
  orderDetails: [],
  orderStats: [],
  isFirstOrder: false,
  isLoading: false,
  isGettingPaymentLinkLoading: false,
};

export const getStudentsOrders = createAsyncThunk('orders/getStudentsOrders', async () => {
  return API.OrdersService.getStudentOrders();
});

export const getIsUserFirstOrder = createAsyncThunk('orders/getIsUserFirstOrder', async () => {
  return API.OrdersService.getIsUserFirstOrder();
});

export const getStudentsOrderDetails = createAsyncThunk('orders/getStudentsOrderDetails', async payload => {
  return API.OrdersService.getStudentsOrderDetails(payload);
});

export const returnOrder = createAsyncThunk('orders/returnOrder', async payload => {
  return API.OrdersService.returnOrder(payload);
});

export const getInvoicePdf = createAsyncThunk('orders/getInvoicePdf', async payload => {
  return API.OrdersService.getInvoicePdf(payload);
});

export const createOrder = createAsyncThunk('orders/createOrder', async payload => {
  return API.OrdersService.createOrder(payload);
});

export const getPaymentLink = createAsyncThunk('orders/getPaymentLink', async payload => {
  return API.OrdersService.getPaymentURL(payload);
});

export const getOrderStats = createAsyncThunk('orders/getOrderStats', async payload => {
  return API.OrdersService.getOrderStats(payload);
});

export const getOrderStatsMonthly = createAsyncThunk('orders/getOrderStatsMonthly', async payload => {
  return API.OrdersService.getOrdersMonthlyStats(payload);
});

export const getOrderStatsMonthlyPackages = createAsyncThunk('orders/getOrderStatsMonthlyPackages', async payload => {
  return API.OrdersService.getOrdersMonthlyPackagesStats(payload);
});

export const getOrderStatsYearlyPackages = createAsyncThunk('orders/getOrderStatsYearlyPackages', async payload => {
  return API.OrdersService.getOrdersYearlyPackagesStats(payload);
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: {
    [getStudentsOrders.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentsOrders.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać zamówień!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getStudentsOrders.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.data;
    },
    [getStudentsOrderDetails.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentsOrderDetails.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać szczegółów zamówienia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getStudentsOrderDetails.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.orderDetails = action.payload.data[0];
    },
    [createOrder.pending.toString()]: state => {
      state.isLoading = true;
    },
    [createOrder.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się stworzyć zamówienia',
        description: `${
          action.error.message === 'Invalid company NIP'
            ? 'Niepoprawny NIP'
            : action.error.message === 'You cart is empty'
            ? 'Twój koszyk jest pusty'
            : action.error.message === 'Problem with cart'
            ? 'Problem z koszykiem'
            : action.error.message === 'OrderUserDetail already exists'
            ? 'Takie zamówienie już istnieje'
            : action.error.message === 'Validation failed'
            ? 'Sprawdź, czy wszystkie pola zostały uzupełnione poprawnie.'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [createOrder.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Utworzono zamówienie',
        placement: 'bottomRight',
      });
    },
    [getPaymentLink.pending.toString()]: state => {
      state.isGettingPaymentLinkLoading = true;
    },
    [getOrderStats.fulfilled.toString()]: (state, action) => {
      state.orderStats = action.payload.data;
    },
    [getOrderStatsMonthly.fulfilled.toString()]: (state, action) => {
      state.monthlyOrdersStats = action.payload.data;
    },
    [getOrderStatsMonthlyPackages.fulfilled.toString()]: (state, action) => {
      state.monthlyPackagesStats = action.payload.data;
    },
    [getOrderStatsYearlyPackages.fulfilled.toString()]: (state, action) => {
      state.yearlyPackagesStats = action.payload.data;
    },
    [getIsUserFirstOrder.fulfilled.toString()]: (state, action) => {
      state.isFirstOrder = action.payload.data;
    },

    [getPaymentLink.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać linku do płatności',
        description: `${
          action.error.message === 'Order doesnt exist'
            ? 'Zamówienie nie istnieje'
            : action.error.message === 'Order has the other status then created'
            ? 'Zamówienie ma inny status niż "utworzone"'
            : action.error.message === 'Order paid'
            ? 'Zamówienie opłacone'
            : action.error.message === 'Card opend'
            ? 'Koszyk otwarty'
            : action.error.message === 'Empty card'
            ? 'Pusty koszyk'
            : action.error.message === 'Too small amount'
            ? 'Zbyt mała kwota zamówienia'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [getPaymentLink.fulfilled.toString()]: state => {
      state.isLoading = false;
    },
    [returnOrder.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zwrócić zamówienia',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [returnOrder.fulfilled.toString()]: state => {
      notification.success({
        message: 'Zamówienie zostało zwrócone',
        placement: 'bottomRight',
      });
    },
  },
});

export default ordersSlice.reducer;
