import actions from './actions';

const initialState = {
  collapsed: false,
};

const siderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_COLLAPSED:
      return {
        ...state,
        collapsed: action.collapsed,
      };
    default:
      return state;
  }
};

export default siderReducer;
