import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';

const Maintance = lazy(() => import('../container/maintance'));

const ErrorRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path="/" component={Maintance} />
      </Suspense>
    </Switch>
  );
};

export default ErrorRoutes;
