import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const AccessCodes = lazy(() => import('../container/accesscodes'));

const AccessCodesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AccessCodes} />
    </Switch>
  );
};

export default AccessCodesRoutes;
