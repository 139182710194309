export const kmiTheme = {
  primaryButtonsColor: '#2280F4',
  disabledButtonsColor: '#2280F460',
  hoverButtonsColor: '#061A2C',
  inputsFocusShadowColor: '#D9EAFD',
  registerSuccessBackground: 'rgba(34, 128, 255, 0.1)',
  myProfileNavlinkHover: '#F0F7FF',
  avatarBorder: '#0093E0',
  sendAnswerMaterialsDisabledColor: '#D7DBDE',
  disabledOpenMaterialsColor: '#1890ff',
  hoverReportErrorButtons: '#4da3ff',
  editorButtonsHover: '#2B64C6',
  gradientDashboardStats: 'transparent linear-gradient(112deg, #145790 0%, #64BDEB 100%) 0% 0% no-repeat padding-box',
  gradientMessagesHeaderDashboard:
    'transparent linear-gradient(100deg, #EC788E 0%, #EC8B4A 100%) 0% 0% no-repeat padding-box',
  surveysHeaderDashboard: '#A0A9B1',
  acceptedExercisesStatColor: '#2280F4',
  '--primary-buttons-color': '#2280F4',
  '--disabled-buttons-color': '#2280F460',
  '--hover-buttons-color': '#061A2C',
  '--inputs-focus-shadow-color': '#D9EAFD',
  '--my-profile-navlink-hover': '#F0F7FF',
  '--avatar-border': '#0093E0',
  '--send-anser-materials-disabled-color': '#D7DBDE',
  '--register-buttons-border-radius': '10px',
  '--hover-report-error-buttons': '#4da3ff',
  '--editor-buttons-hover': '#2B64C6',
};

export const kmmTheme = {
  primaryButtonsColor: '#508D4E',
  disabledButtonsColor: '#F2F7F2',
  hoverButtonsColor: '#292929',
  inputsFocusShadowColor: '#F2F7F2',
  registerSuccessBackground: '#F2F7F2',
  myProfileNavlinkHover: '#F2F7F2',
  avatarBorder: '#508D4E',
  sendAnswerMaterialsDisabledColor: '#F2F7F2',
  disabledOpenMaterialsColor: '#508D4E',
  hoverReportErrorButtons: '#F2F7F2',
  editorButtonsHover: '#508D4E',
  gradientDashboardStats: 'transparent linear-gradient(107deg, #65AC62 0%, #5D695D 100%) 0% 0% no-repeat padding-box',
  gradientMessagesHeaderDashboard:
    'transparent linear-gradient(99deg, #EC8B4A 0%, #ECC04A 100%) 0% 0% no-repeat padding-box',
  surveysHeaderDashboard: '#A0A9B1',
  acceptedExercisesStatColor: '#878EAA',
  '--primary-buttons-color': '#508D4E',
  '--disabled-buttons-color': '#D9E2D9',
  '--hover-buttons-color': '#292929',
  '--inputs-focus-shadow-color': '#F2F7F2',
  '--my-profile-navlink-hover': '#F2F7F2',
  '--avatar-border': '#508D4E',
  '--send-anser-materials-disabled-color': '#F2F7F2',
  '--register-buttons-border-radius': '7px',
  '--hover-report-error-buttons': '#F2F7F2',
  '--editor-buttons-hover': '#508D4E',
};
