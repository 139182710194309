/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  questions: [],
  stats: {
    studentAnswers: 0,
    allQuestions: 0,
  },
  isLoading: true,
  isStatsLoading: true,
};

export const getQuestionsWithStats = createAsyncThunk('questions/getQuestionsWithStats', async () => {
  return API.QuizService.getQuestionsWithStats();
});

export const addQuestion = createAsyncThunk('questions/addQuestion', async payload => {
  return API.QuizService.addQuestion(payload);
});

export const editQuestion = createAsyncThunk('questions/editQuestion', async payload => {
  return API.QuizService.editQuestion(payload);
});

const quizQuestionSlice = createSlice({
  name: 'quizquestions',
  initialState,
  reducers: {},
  extraReducers: {
    [getQuestionsWithStats.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getQuestionsWithStats.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać pytań!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getQuestionsWithStats.fulfilled.toString()]: (state, action) => {
      state.questions = action.payload.data.questions;
      state.stats.studentAnswers = action.payload.data.userAnswerCount;
      state.isLoading = false;
    },
    [addQuestion.pending.toString()]: state => {
      state.isStatsLoading = true;
      state.questions = [];
    },
    [addQuestion.rejected.toString()]: (state, action) => {
      state.isStatsLoading = false;
      notification.error({
        message: 'Nie udało się dodać pytania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addQuestion.fulfilled.toString()]: (state, action) => {
      state.isStatsLoading = false;
      notification.success({
        message: 'Dodano pytanie!',
        placement: 'bottomRight',
      });
    },
    [editQuestion.pending.toString()]: state => {
      state.isLoading = true;
      state.questions = [];
    },
    [editQuestion.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało zmienić pytania!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editQuestion.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      notification.success({
        message: 'Pytanie zmienione!',
        placement: 'bottomRight',
      });
    },
  },
});

export const {} = quizQuestionSlice.actions;

export default quizQuestionSlice.reducer;
